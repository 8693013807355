import { useEffect } from "react";
import { useSelector } from "react-redux";
import PreStepAnimationContainer from "../../components/PreStepAnimationContainer";
import { IStepFlowComponentBase, StepFlowEventType } from "../../types/StepFlowComponent.types";
import { selectProfilePhoto } from "../../features/photosManagment/sessionPhotosSelectors";
import { selectCurrentSession } from "../UserWorkFlow/selectors";
import useImageDimensions from "../../hooks/getImageDimensionsHook";


export interface IHaireColorSelectionPreStepAnimationProps extends IStepFlowComponentBase { }

const HaireColorSelectionPreStepAnimationStep = ({ onComponentEvent }: IHaireColorSelectionPreStepAnimationProps) => {
    const profilePhoto = useSelector(selectProfilePhoto);
    const userWorkFlow = useSelector(selectCurrentSession);
    const pictureDimensions = useImageDimensions(profilePhoto!);
    const hairAnimationCoordinates = userWorkFlow?.faceInfo?.geometry?.hair;
    const animationOffset = {
        top: 0,
    };
    useEffect(() => {
    }, [pictureDimensions]);

    useEffect(() => {
        const haireAnimation = setTimeout(() => {
            onComponentEvent({ type: StepFlowEventType.completed });
        }, 5000);

        return () => {
            clearTimeout(haireAnimation);
        };
    }, [profilePhoto]);

    return (
        <PreStepAnimationContainer
            picture={profilePhoto!}
            top={animationOffset.top}
            left={(hairAnimationCoordinates?.left! * 100) / pictureDimensions.width!}
        />
    );
};

export default HaireColorSelectionPreStepAnimationStep;
