import { useTranslation } from "react-i18next";

export interface INuanceColorSelectionStepActionsProps {
  colorSelected: boolean;
  validateTemperatureEvent: () => void;

}
const EyeTemperatureColorSelectionStepActions = ({ colorSelected, validateTemperatureEvent }: INuanceColorSelectionStepActionsProps) => {
  const { t } = useTranslation("common");
  return (
    <>
      <button disabled={!colorSelected} onClick={validateTemperatureEvent} className="color_select_button">
        {t("eyeTemperatureSelection.validateTemperature")}
      </button>

    </>
  );
};
export default EyeTemperatureColorSelectionStepActions;