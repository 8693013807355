import { useTranslation } from "react-i18next";

export interface INuanceColorSelectionStepActionsProps {
  colorSelected: boolean;
  validateTintNuanceEvent: () => void;

}
const TintNuanceSelectionStepActions = ({ colorSelected, validateTintNuanceEvent }: INuanceColorSelectionStepActionsProps) => {
  const { t } = useTranslation("common");
  return (
    <>
      <button disabled={!colorSelected} onClick={validateTintNuanceEvent} className="color_select_button">
        {t("tintNuanceSelection.validateNuance")}
      </button>

    </>
  );
};
export default TintNuanceSelectionStepActions;