import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { useStepsFlowComponentsManager } from "../../hooks/StepsFlowComponentsManager";
import { IStepFlowComponent, IStepFlowDefinition, IStepFlowEvent, StepFlowEventType } from "../../types/StepFlowComponent.types";
import { selectCurrentSession, selectCurrentStepFLowDefinition } from "../UserWorkFlow/selectors";
import { useAppDispatch } from "../../hooks";

import { useGetColorsByColorIdQueryMutation, useGetColorsCategoryByTypeQueryMutation } from "../../features/colorsManagment/apiSlice";
import { setEyePatternBrightnessList, setEyePatternTypeList } from "../../features/colorsManagment/sessionColorsSlice";
import { selectEyePatternBrightness } from "../../features/colorsManagment/sessionColorsSelectors";
import EyePatternSelectionStepComponent from "./EyePatternSelectionStepComponent";
import EyePatternCategorySelectionStepComponent from "./EyePatternCategorySelectionStep";
import { useErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useSaveUserProgressHelperHook } from "../../hooks/SaveUserProgressHelperHook";
import InstructionPageStepContainer from "../../components/InstructionPageStepContainer";
import EyePatternInstructionAction from "./EyePatternInstructionAction";

const EyePatternWorkFlow = () => {
  const { showBoundary } = useErrorBoundary();
  const { t } = useTranslation("common");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentStepFlow = useSelector(selectCurrentStepFLowDefinition);
  const userProgress = useSelector(selectCurrentSession);
  const selectedEyePatternBrightness = useSelector(selectEyePatternBrightness);
  const [selectedPatternCategoryId, setSelectedPatternCategoryId] = useState<string>("");

  const [getColorsDataQuery] = useGetColorsCategoryByTypeQueryMutation();
  const [getColorNuanceDataQuery] = useGetColorsByColorIdQueryMutation();
  const [saveProgressAndNavigate, isLoading] = useSaveUserProgressHelperHook(showBoundary);

  const eyeStepFlowId = "11111111-1111-1111-1111-300000000000";

  const getEyePatternList = async () => {
    const data = await getColorsDataQuery(4);
    if ("data" in data) {
      dispatch(setEyePatternBrightnessList(data.data));
    }
  };

  const getEyePatternTypeList = async (id: string) => {
    const data = await getColorNuanceDataQuery(id);
    if ("data" in data) {
      dispatch(setEyePatternTypeList(data.data));
    }
  };

  useEffect(() => {
    getEyePatternList();
  }, []);

  useEffect(() => {
    selectedEyePatternBrightness?.id && getEyePatternTypeList(selectedEyePatternBrightness.id);
  }, [selectedEyePatternBrightness]);

  const onComponentEvent = (event: IStepFlowEvent) => {
    switch (event.type) {
      case StepFlowEventType.Reset:
        reset();
        resetEyeStepFlow();
        break;
      case StepFlowEventType.completed:
        setSelectedPatternCategoryId((pre) => (pre = event.params![0].id));
        handleNext();
        break;
      case StepFlowEventType.GoToStep:
        goToStep(event.params![0].stepIndex);
        break;
      // specific eyes step event
      case StepFlowEventType.SelectEyePattern:
        onValidateStepFlowEvent(event.params![0].id);
        break;
      default:
        break;
    }
  };

  const resetEyeStepFlow = async () => {
    const _progress = cloneDeep(userProgress);
    // clone eyeStepflow
    const _currentStepFlow = _progress!.steps?.find(i => i.id === eyeStepFlowId);
    if (!_currentStepFlow || !_progress) return;
    // reset eyeStepFlow result then save progress
    _currentStepFlow!.result!.isCompleted = false;
    _currentStepFlow!.result!.colorId = undefined;
    _currentStepFlow!.result!.categoryId = undefined;
    _currentStepFlow!.result!.categoryId = undefined;

    const stepDefinition: IStepFlowDefinition = { step: _currentStepFlow, stepRoute: undefined, stepIndex: undefined };
    await saveProgressAndNavigate(_progress, stepDefinition, dispatch, navigate);
  };

  const onValidateStepFlowEvent = async (patternId: any) => {
    // set step result
    const _currentStepFlow = cloneDeep(currentStepFlow);
    const _progress = cloneDeep(userProgress);

    if (!_currentStepFlow || !_progress) return;

    _currentStepFlow.step!.result!.isCompleted = true;
    _currentStepFlow!.step!.result!.categoryId = selectedPatternCategoryId;
    _currentStepFlow!.step!.result!.colorId = patternId;

    await saveProgressAndNavigate(_progress, _currentStepFlow, dispatch, navigate);
  };


  // Define Workflow steps
  const eyePatternWorkFlow: IStepFlowComponent[] = [
    {
      order: 0,
      component: (
        <InstructionPageStepContainer frameNote={currentStepFlow?.step?.frameNote}
          instructionStepImages="pattern"
          actionsComponent={<EyePatternInstructionAction onComponentEvent={onComponentEvent} />}
        />
      ),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        displayLogo: false,
        workFlowStep: t("stepper.pattern"),
        workFlow: t("stepper.eye"),
        workFlowProgress: 11,
        hideStapper: true,
        showCorners: true,
      },
    },
    {
      order: 1,
      component: (
        <EyePatternCategorySelectionStepComponent onComponentEvent={onComponentEvent} />
      ),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        displayLogo: false,
        workFlowStep: t("stepper.pattern"),
        workFlow: t("stepper.eye"),
        workFlowProgress: 11,
        hideStapper: true,
      },
    },
    {
      order: 2,
      component: (
        <EyePatternSelectionStepComponent
          onComponentEvent={onComponentEvent}
          isLoading={isLoading}
        />
      ),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        displayLogo: false,
        workFlowStep: t("stepper.pattern"),
        workFlow: t("stepper.eye"),
        workFlowProgress: 12,
        hideStapper: true,
      },
    },
  ];

  const [activeStepIndex, , handleNext, reset,] = useStepsFlowComponentsManager({ stepsComponents: eyePatternWorkFlow });

  return <div>{eyePatternWorkFlow[activeStepIndex]?.component}</div>;
};

export default EyePatternWorkFlow;
function goToStep(stepIndex: any) {
  throw new Error("Function not implemented.");
}

