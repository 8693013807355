import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { cloneDeep } from "lodash";
import { useStepsFlowComponentsManager } from "../../hooks/StepsFlowComponentsManager";
import VideoContainer from "../../components/VideoContainer";
import { IStepFlowComponent, IStepFlowEvent, StepFlowEventType } from "../../types/StepFlowComponent.types";
import InstructionPageStepContainer from "../../components/InstructionPageStepContainer";
import { selectCurrentSession, selectCurrentStepFLowDefinition } from "../UserWorkFlow/selectors";
import HaireInstructionStepActions from "./haireInstructionStepActions";
import ValidateHairePhotoStepComponent from "./validateHairePhotoStep";
import HaireTakePhotoStepComponent from "./hairePhotoStep";
import HaireColorSelectionStepComponent from "./haireColorSelectionStep";
import HaireColorSelectionPreStepAnimationStep from "./haireColorSelectionPreStepAnimationStep";
import HaireColorSelectionAfterStepAnimationStep from "./haireColorSelectionAfterStepAnimationStep";
import NuanceColorSelectionStepComponent from "./nuaceColorSelectionStep";
import { useAppDispatch } from "../../hooks";
import { useGetColorsByColorIdQueryMutation, useGetColorsCategoryByTypeQueryMutation } from "../../features/colorsManagment/apiSlice";
import { setHairSelectedColor, setHairColorNuanceList, setHairColorsList } from "../../features/colorsManagment/sessionColorsSlice";
import { selectHairColor } from "../../features/colorsManagment/sessionColorsSelectors";
import { useTranslation } from "react-i18next";
import { useErrorBoundary } from "react-error-boundary";
import { useSaveUserProgressHelperHook } from "../../hooks/SaveUserProgressHelperHook";

const HaireWorkFlow = () => {
  const { t } = useTranslation("common");
  const { showBoundary } = useErrorBoundary();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const currentStepFlow = useSelector(selectCurrentStepFLowDefinition);
  const userProgress = useSelector(selectCurrentSession);
  const hairSelectedColor = useSelector(selectHairColor);

  const [saveProgressAndNavigate, isLoading] = useSaveUserProgressHelperHook(showBoundary);
  const [getColorsDataQuery] = useGetColorsCategoryByTypeQueryMutation();
  const [getColorNuanceDataQuery] = useGetColorsByColorIdQueryMutation();

  const getHairColorsData = async () => {
    const data = await getColorsDataQuery(1);
    if ("data" in data) {
      dispatch(setHairColorsList(data.data));
    }
  };

  const getHiarColorNuanceData = async (id: string) => {
    const data = await getColorNuanceDataQuery(id);
    if ("data" in data) {
      dispatch(setHairColorNuanceList(data.data));
    }
  };

  useEffect(() => {
    getHairColorsData();
  }, []);

  useEffect(() => {
    hairSelectedColor && getHiarColorNuanceData(hairSelectedColor?.id!);
  }, [hairSelectedColor]);


  const onComponentEvent = (event: IStepFlowEvent) => {
    switch (event.type) {
      case StepFlowEventType.Reset:
        reset();
        break;
      case StepFlowEventType.skip:
      case StepFlowEventType.completed:
        handleNext();
        break;
      case StepFlowEventType.TakePicture:
        goToStep(3);
        break;
      case StepFlowEventType.LoadPicture:
        goToStep(3);
        break;
      case StepFlowEventType.GoToStep:
        goToStep(event.params![0].stepIndex);
        break;
      // specific haire step event 
      case StepFlowEventType.SelectColor:
        dispatch(setHairSelectedColor(event.params![0]));
        handleNext();
        break;
      case StepFlowEventType.SelectNuance:
        onValidateStepFlowEvent(event.params![0].id);
        break;
      default:
        break;
    }
  };

  const onValidateStepFlowEvent = async (nuanceColorId: any) => {
    // set step result 
    const _currentStepFlow = cloneDeep(currentStepFlow);
    const _progress = cloneDeep(userProgress);
    if (!_currentStepFlow || !_progress)
      return;

    _currentStepFlow.step!.result!.isCompleted = true;
    _currentStepFlow!.step!.result!.categoryId = hairSelectedColor?.id!;
    _currentStepFlow!.step!.result!.colorId = nuanceColorId;

    await saveProgressAndNavigate(_progress, _currentStepFlow, dispatch, navigate);

  };

  // Define Workflow steps
  const phtoProfileWorkFlow: IStepFlowComponent[] = [
    {
      order: 0,
      component: (
        <VideoContainer
          videoUrl="../assets/video/Intro.mp4"
          onComponentEvent={onComponentEvent}
        />
      ),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        hideStapper: false,
        showCorners: true
      },
    },
    {
      order: 1,
      component: (
        <InstructionPageStepContainer
          frameNote={currentStepFlow?.step?.frameNote}
          instructionStepImages="hair"
          actionsComponent={
            <HaireInstructionStepActions onComponentEvent={onComponentEvent} />
          }
        />
      ),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        displayLogo: false,
        workFlowStep: t("stepper.photo"),
        workFlow: t("stepper.hair"),
        workFlowProgress: 1,
        hideStapper: true,
        showCorners: true
      },
    },
    {
      order: 2,
      component: (
        <HaireTakePhotoStepComponent onComponentEvent={onComponentEvent} />
      ),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        displayLogo: false,
        workFlowStep: t("stepper.photo"),
        workFlow: t("stepper.hair"),
        workFlowProgress: 2,
        hideStapper: true,
        showCorners: true
      },
    },
    {
      order: 3,
      component: (
        <ValidateHairePhotoStepComponent onComponentEvent={onComponentEvent} />
      ),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        displayLogo: false,
        workFlowStep: t("stepper.reframe"),
        workFlow: t("stepper.hair"),
        workFlowProgress: 3,
        hideStapper: true,
        showCorners: true
      },
    },
    {
      order: 4,
      component: (
        <HaireColorSelectionPreStepAnimationStep
          onComponentEvent={onComponentEvent}
        />
      ),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        hideStapper: false,
        showCorners: false,
      },
    },
    {
      order: 5,
      component: (
        <HaireColorSelectionStepComponent onComponentEvent={onComponentEvent} />
      ),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        displayLogo: false,
        workFlowStep: t("stepper.color"),
        workFlow: t("stepper.hair"),
        workFlowProgress: 4,
        hideStapper: true,
        showCorners: true
      },
    },
    {
      order: 6,
      component: (
        <HaireColorSelectionAfterStepAnimationStep
          onComponentEvent={onComponentEvent}
        />
      ),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        hideStapper: false,
        showCorners: false
      },
    },
    {
      order: 7,
      component: (
        <NuanceColorSelectionStepComponent
          onComponentEvent={onComponentEvent}
          // validateStepFlowEvent={onValidateStepFlowEvent}
          isLoading={isLoading}
        />
      ),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        displayLogo: false,
        workFlowStep: t("stepper.nuance"),
        workFlow: t("stepper.hair"),
        workFlowProgress: 5,
        hideStapper: true,
        showCorners: true
      },
    },
  ];

  const [activeStepIndex, isCompleted, handleNext, reset, goToStep] = useStepsFlowComponentsManager({ stepsComponents: phtoProfileWorkFlow });

  return <div>{phtoProfileWorkFlow[activeStepIndex]?.component}</div>;
};

export default HaireWorkFlow;
