import { useSelector } from "react-redux";
import { IStepFlowComponentBase, StepFlowEventType } from "../../types/StepFlowComponent.types";
import { useEffect } from "react";
import { selectProfilePhoto } from "../../features/photosManagment/sessionPhotosSelectors";
import AfterStepAnimationContainer from "../../components/AfterStepAnimationContainer";
import { selectEyeColor, selectEyeColorNuances } from "../../features/colorsManagment/sessionColorsSelectors";


export interface IEyeColorSelectionAfterStepAnimationProps extends IStepFlowComponentBase { }

const EyeColorSelectionLastAfterStepAnimationStep = ({ onComponentEvent }: IEyeColorSelectionAfterStepAnimationProps) => {
  const profilePhoto = useSelector(selectProfilePhoto);
  const selectedEyeColor = useSelector(selectEyeColor);
  const selectedEyeColorNuance = useSelector(selectEyeColorNuances);
  useEffect(() => {
    const EyeAnimation = setTimeout(() => {
      onComponentEvent({ type: StepFlowEventType.completed });
    }, 5000);

    return () => {
      clearTimeout(EyeAnimation);
    };
  }, [profilePhoto]);

  return (
    <AfterStepAnimationContainer
      picture={profilePhoto!}
      top={230}
      left={90}
      nextSubStepName="Pattern"
      colorName={selectedEyeColor?.name!}
      colorHexCode={selectedEyeColor?.color!}
      colorNuance={selectedEyeColorNuance?.subfamilly!}
    />
  );
};

export default EyeColorSelectionLastAfterStepAnimationStep;
