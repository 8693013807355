import { useSelector } from "react-redux";
import {
  IStepFlowComponentBase,
  StepFlowEventType,
} from "../../types/StepFlowComponent.types";
import { useAppDispatch } from "../../hooks";
import UserPictureValidationContainer from "../../components/PictureValidationContainer";
import ValidateHairePhotoStepActions from "./validateHairePhotoStepActions";
import { selectHaireValidationPhoto } from "../../features/photosManagment/sessionPhotosSelectors";
import {
  resetCurrentSessionPhoto,
  setCurrentSessionPhotoAsValidatedHairePhoto,
  setValidatedHairePhoto,
} from "../../features/photosManagment/sessionPhotosSlice";

export interface IalidateHairePhotoStepProps extends IStepFlowComponentBase { }

const ValidateHairePhotoStepComponent = ({
  onComponentEvent,
}: IalidateHairePhotoStepProps) => {
  const profilePhoto = useSelector(selectHaireValidationPhoto);
  const dispatch = useAppDispatch();

  const handlePictureValidation = () => {
    dispatch(setCurrentSessionPhotoAsValidatedHairePhoto());
    onComponentEvent({
      type: StepFlowEventType.GoToStep,
      params: [{ stepIndex: 4 }],
    });
  };

  const handleCropperEvent = (e: string) => {
    dispatch(setValidatedHairePhoto(e));
  };

  const handleTakeAnOtherPhotoEvent = () => {
    dispatch(resetCurrentSessionPhoto());
    onComponentEvent({
      type: StepFlowEventType.GoToStep,
      params: [{ stepIndex: 2 }],
    });
  };

  const handleGoBackFunction = () => {
    dispatch(resetCurrentSessionPhoto());
    onComponentEvent({ type: StepFlowEventType.Reset });
  };

  return (
    <UserPictureValidationContainer
      rounded={true}
      picture={profilePhoto}
      displayFaceFrame={false}
      cropperSize={150}
      backEvent={handleGoBackFunction}
      cropperEvent={(e) => handleCropperEvent(e)}
      actionsComponent={
        <ValidateHairePhotoStepActions
          takeAnOtherPhotoEvent={handleTakeAnOtherPhotoEvent}
          validatePictureEvent={handlePictureValidation}
        />
      }
    />
  );
};
export default ValidateHairePhotoStepComponent;
