import { useSelector } from "react-redux";
import { IStepFlowComponentBase, StepFlowEventType } from "../../types/StepFlowComponent.types";
import { useEffect } from "react";
import { selectProfilePhoto } from "../../features/photosManagment/sessionPhotosSelectors";
import AfterStepAnimationContainer from "../../components/AfterStepAnimationContainer";
import { selectEyeColor } from "../../features/colorsManagment/sessionColorsSelectors";
import { selectCurrentSession } from "../UserWorkFlow/selectors";
import useImageDimensions from "../../hooks/getImageDimensionsHook";


export interface IEyeColorSelectionAfterStepAnimationProps extends IStepFlowComponentBase { }

const EyeColorSelectionAfterStepAnimationStep = ({ onComponentEvent }: IEyeColorSelectionAfterStepAnimationProps) => {
  const profilePhoto = useSelector(selectProfilePhoto);
  const selectedEyeColor = useSelector(selectEyeColor);
  const userWorkFlow = useSelector(selectCurrentSession);
  const pictureDimensions = useImageDimensions(profilePhoto!);
  const eyeAnimationCoordinates = userWorkFlow?.faceInfo?.geometry?.rightEye;

  const animationOffset = {
    top: 5,
    right: 12
  };

  useEffect(() => {
    const EyeAnimation = setTimeout(() => {
      onComponentEvent({ type: StepFlowEventType.completed });
    }, 5000);

    return () => {
      clearTimeout(EyeAnimation);
    };
  }, [profilePhoto]);

  return (
    <AfterStepAnimationContainer
      picture={profilePhoto!}
      top={((eyeAnimationCoordinates?.top! * 100) / pictureDimensions.height!) - animationOffset.top}
      left={((eyeAnimationCoordinates?.right! * 100) / pictureDimensions.width!) - animationOffset.right}
      nextSubStepName="Nuance"
      colorName={selectedEyeColor?.title!}
      colorHexCode={selectedEyeColor?.color!}
    />
  );
};

export default EyeColorSelectionAfterStepAnimationStep;
