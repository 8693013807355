import { useEffect } from "react";
import { useSelector } from "react-redux";

import PreStepAnimationContainer from "../../components/PreStepAnimationContainer";
import { IStepFlowComponentBase, StepFlowEventType } from "../../types/StepFlowComponent.types";
import { selectProfilePhoto } from "../../features/photosManagment/sessionPhotosSelectors";
import { selectCurrentSession } from "../UserWorkFlow/selectors";
import useImageDimensions from "../../hooks/getImageDimensionsHook";

export interface ITintColorSelectionPreStepAnimationProps extends IStepFlowComponentBase { }

const TintColorSelectionPreStepAnimationStep = ({ onComponentEvent }: ITintColorSelectionPreStepAnimationProps) => {
  const profilePhoto = useSelector(selectProfilePhoto);
  const userWorkFlow = useSelector(selectCurrentSession);
  const imageDimensions = useImageDimensions(profilePhoto!);
  const tintAnimationCoordinates = userWorkFlow?.faceInfo?.geometry?.leftCheek;

  const animationOffset = {
    top: 18
  };

  useEffect(() => {
    const TintAnimation = setTimeout(() => {
      onComponentEvent({ type: StepFlowEventType.completed });
    }, 5000);

    return () => {
      clearTimeout(TintAnimation);
    };
  }, [profilePhoto]);

  return (
    <PreStepAnimationContainer
      picture={profilePhoto!}
      top={((tintAnimationCoordinates?.top! * 100) / imageDimensions.height!) + animationOffset.top}
      left={(tintAnimationCoordinates?.left! * 100) / imageDimensions.width!}
    />
  );
};

export default TintColorSelectionPreStepAnimationStep;
