import { useState } from 'react';

import logo from '../assets/image/logo/logo.svg';
import subLogo from '../assets/image/logo/logoSub.svg';
import openIcon from '../assets/image/menu/burgerMenu.svg';
import closeIcon from '../assets/image/menu/closeMenu.svg';
import tiktokLogo from '../assets/image/menu/tiktok.svg';

import Corners from './Corners';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUserProfile } from '../auth/selectors';
import { useTranslation } from 'react-i18next';
import { useLogOutQueryMutation } from '../auth/authApiSlice';
import { toggleLanguage } from '../hooks/GetUserBrowserLanguageHook';

const Menu = () => {
  const { t } = useTranslation("common");
  const userProfile = useSelector(selectUserProfile);
  const [logOutQuery] = useLogOutQueryMutation();

  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const baseUrl = process.env.REACT_APP_BACKEND_URL;

  const menuToggle = () => setOpenMenu(!openMenu);
  const handleSignOut = () => { logOutQuery(null).unwrap().then(() => { window.location.reload(); }); };
  const handleVisitTitok = () => window.open('https://www.tiktok.com/@magnifisenses', '_blank');

  return (
    <>
      <button className='menu_toggle_open' onClick={menuToggle}>
        <img src={openIcon} alt='menu icon' />
      </button>

      <aside className={openMenu ? 'menu_container open' : 'menu_container'}>
        <button className='menu_toggle_close' onClick={menuToggle}>
          <img src={closeIcon} alt='close icon' />
        </button>

        <img className='menu_logo' src={logo} alt='magnifisense logo' />

        <div className='menu_container_main'>

          <div className='profile_pic'>
            <img src={userProfile?.pictureId ? `${baseUrl}/photo/fetch/${userProfile.pictureId}` : subLogo} alt="" />
          </div>

          <h2 className='menu_container_header'>{t("menu.title")} <span>{userProfile?.userInfo?.firstName}</span></h2>

          <Link to='' className='menu_container_connect' onClick={handleSignOut}>
            {t("menu.disconnect")}
          </Link>
        </div>


        <div className="Magnifisenses_tiktok_link" onClick={handleVisitTitok}>
          <img src={tiktokLogo} alt="" />
          <p>Magnifisenses</p>
        </div>

        <button className='menu_english_version' onClick={() => toggleLanguage()}>
          <p>{t("menu.version")}</p>
        </button>

        <div className='links'>
          <a href='.' className='menu_link'>
            {t("menu.legalNotice")}
          </a>
          <a href='.' className='menu_link'>
            {t("menu.privacyPolicy")}
          </a>
        </div>
      </aside>
    </>
  );
};

export default Menu;
