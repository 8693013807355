import { MutableRefObject, useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Webcam from "react-webcam";

import cameraFlip from "../assets/image/camera/flip_camera.svg";
import cameraFrame from "../assets/image/camera/cameraFrame.svg";
import eyeFrame from "../assets/image/camera/cameraEyeFrame.svg";
import hairFrame from "../assets/image/camera/cameraHairFrame.svg";
import backArrow from "../assets/image/menu/backArrow.svg";


const isMobile = /Mobile/.test(navigator.userAgent);
const isTablet = /Tablet/.test(navigator.userAgent);

interface ICameraContainerProps {
  webCamRef: MutableRefObject<Webcam | null>;
  displayFaceFrame: boolean;
  displayEyeFrame?: boolean;
  displayHairFrame?: boolean;
  hightResolution?: boolean;
  captureEvent: (event: string | null | undefined) => void;
  backEvent: () => void;
}

const CameraContainer = ({
  webCamRef,
  displayFaceFrame,
  displayEyeFrame = false,
  displayHairFrame = false,
  hightResolution,
  captureEvent,
  backEvent
}: ICameraContainerProps) => {
  const [isFrontCamera, setIsFrontCamera] = useState(true);
  const { t } = useTranslation("common");
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const capture = useCallback(() => {
    const imageSrc = webCamRef.current?.getScreenshot();
    if (imageSrc) {
      const image = new Image();
      image.src = imageSrc;
      image.onload = () => {
        const canvas = canvasRef.current;
        if (canvas) {
          const ctx = canvas.getContext("2d");
          if (ctx) {
            canvas.width = image.width;
            canvas.height = image.height;
            ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
            const enhancedImageSrc = canvas.toDataURL("image/jpeg", 1.0);
            captureEvent(enhancedImageSrc);
          }
        }
      };
    }
  }, [webCamRef]);

  const handleBack = () => {
    backEvent();
  };

  const handleChangeCamera = () => {
    setIsFrontCamera(!isFrontCamera);
  };

  return (
    <>
      <div className="camera_component">
        <button className="back_button" onClick={handleBack}>
          <img src={backArrow} alt="" />
          <p>{t("cameraContainer.return")}</p>
        </button>
        <div className="camera_container">
          <Webcam
            className="camera"
            ref={webCamRef}
            screenshotFormat="image/jpeg"
            forceScreenshotSourceSize={true}
            videoConstraints={{
              facingMode: isFrontCamera ? "user" : "environment",
              aspectRatio: isMobile || isTablet ? 4 / 3 : 3 / 4,
              height: hightResolution ? 1080 : undefined,
              width: hightResolution ? 1920 : undefined
            }}
            screenshotQuality={1}
            mirrored={isFrontCamera ? true : false}
            imageSmoothing={true}
          />
          {displayFaceFrame && <img className="camera_frame" src={cameraFrame} alt="" />}
          {displayEyeFrame && <img src={eyeFrame} alt="" className="camera_eye_frame" />}
          {displayHairFrame && <div className="camera_hair_frame"></div>}
          <canvas ref={canvasRef} style={{ display: "none" }} />
        </div>

        <div className="camera_container_buttons">
          <button className="camera_button" onClick={capture}>
            {t("cameraContainer.captureButton")}
          </button>
          <button className="change_camera_button" onClick={handleChangeCamera}>
            <img src={cameraFlip} alt="" />
          </button>
        </div>
      </div>
    </>
  );
};

export default CameraContainer;
