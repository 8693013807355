import { useTranslation } from "react-i18next";

export interface IValidateHairePhotoStepActionsProps {
  takeAnOtherPhotoEvent: () => void;
  validatePictureEvent: () => void;
}

const ValidateEyePhotoStepActions = ({ takeAnOtherPhotoEvent, validatePictureEvent }: IValidateHairePhotoStepActionsProps) => {
  const { t } = useTranslation("common");
  const handleTakeAnOtherPhoto = () => {
    takeAnOtherPhotoEvent();
  };

  const handlePictureValidation = async () => {
    validatePictureEvent();
  };

  return (
    <>
      <button className="recommencer_button" onClick={handleTakeAnOtherPhoto}>
        {t("validatePhoto.takeAnotherPicture")}
      </button>
      <button className="valide_button" onClick={handlePictureValidation}>
        {t("validatePhoto.validatePicture")}
      </button>

    </>
  );
};

export default ValidateEyePhotoStepActions;
