export class MagRoutes {
  /** Public routes */
  static Index = "/";
  static Login = "/login";
  static Register = "/register";
  static StartExperience = "/start-experience";
  static EmailVerification = "/email-verification";
  static ForgotPassword = "/forgot-password";
  static ResetPassword = "/reset-password";
  static WelcomeVideo = "/welcome-video";
  static SelectColor = "/select-color";
  static CameraContainer = "/camera-Container";

  /** Protected routes */
  static analysisRouter = "/analysis";
  static photoProfileWorkFlow = "/analysis/visage";
  static haireWorkFlow = "/analysis/cheveux";
  static eyeWorkFlow = "/analysis/yeux";
  static eyePatternWorkFlow = "/analysis/yeux-pattern";
  static tintWorkFlow = "/analysis/tint";
  
  /** Checkout */
  static paymentForm = "/checkout";
  static checkoutError = "/checkout-error";

  static processResult = "/color-report";

  static UserProfile = "/profile";
  static UserProfileHome = "/profile/home";

  static NotFound = "*";
  static GlobalError = "/error";
}
