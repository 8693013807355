import { RootState } from "../../store";

export const selectCurrentSessionPhoto = (state: RootState) => state.sessionPhoto.currentSessionPhoto;
export const selectValidatedProfilePhoto = (state: RootState) => state.sessionPhoto.validatedProfilePhoto;
export const selectValidatedHairePhoto = (state: RootState) => state.sessionPhoto.validatedHairePhoto;
export const selectValidatedEyePhoto = (state: RootState) => state.sessionPhoto.validatedEyePhoto;
export const selectValidatedTintPhoto = (state: RootState) => state.sessionPhoto.validatedTintPhoto;

export const selectProfilePhoto = (state: RootState) => {
  return state.sessionPhoto.validatedProfilePhoto !== null
    ? state.sessionPhoto.validatedProfilePhoto
    : `${process.env.REACT_APP_BACKEND_URL}/photo/fetch/${state.sessionPhoto.photoProfileId}`;
};

export const selectHaireValidationPhoto = (state: RootState) => {
  return state.sessionPhoto.currentSessionPhoto !== null
    ? state.sessionPhoto.currentSessionPhoto
    : `${process.env.REACT_APP_BACKEND_URL}/photo/fetch/${state.sessionPhoto.photoProfileId}`;
};

export const selectEyeValidationPhoto = (state: RootState) => {
  return state.sessionPhoto.eyeSessionPhoto !== null
    ? state.sessionPhoto.eyeSessionPhoto
    : `${process.env.REACT_APP_BACKEND_URL}/photo/fetch/${state.sessionPhoto.photoProfileId}`;
};

export const selectTintValidationPhoto = (state: RootState) => {
  return state.sessionPhoto.tintSessionPhoto !== null
    ? state.sessionPhoto.tintSessionPhoto
    : `${process.env.REACT_APP_BACKEND_URL}/photo/fetch/${state.sessionPhoto.photoProfileId}`;
};
