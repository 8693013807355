
import { apiSlice } from "../../api/apiSlice";
import { IWorkflow } from "../../types/backend.types";


export const userWorkflowApiSlice = apiSlice.injectEndpoints({
  // get user progress
  endpoints: (builder) => ({
    getUserProgressQuery: builder.mutation<IWorkflow, any>({
      query: () => ({
        url: "/workflow/fetch",
        method: "GET",
      }),
    }),
    // save user progress
    saveUserProgressQuery: builder.mutation({
      query: (workflow: IWorkflow) => ({
        url: "/workflow/save",
        method: "POST",
        body: { ...workflow },
      }),
    }),
  }),
});

export const {
  useGetUserProgressQueryMutation,
  useSaveUserProgressQueryMutation
} = userWorkflowApiSlice;
