import { RootState } from "../../store";

// hair selectors ---------->
export const selectHairColorsList = (state: RootState) => state.sessionColors.HairColorsList;
export const selectHairColorNuancesList = (state: RootState) => state.sessionColors.HairColorNuancesList;
export const selectHairColor = (state: RootState) => state.sessionColors.HairSelectedColor;
export const selectHairColorNuances = (state: RootState) => state.sessionColors.HairSelectedColorNuance;

// eye selectors ----------->
export const selectEyeColorsList = (state: RootState) => state.sessionColors.EyeColorsList;
export const selectEyeTemperatureList = (state: RootState) => state.sessionColors.EyeTemperatureList;
export const selectEyeColorNuancesList = (state: RootState) => state.sessionColors.EyeColorNuancesList;
export const selectEyePatternBrightnessList = (state: RootState) => state.sessionColors.EyePatternBrightnessList;
export const selectEyePatternTypeList = (state: RootState) => state.sessionColors.EyePatternTypeList;
export const selectEyeColor = (state: RootState) => state.sessionColors.EyeSelectedColor;
export const selectEyeColorNuances = (state: RootState) => state.sessionColors.EyeSelectedColorNuance;
export const selectEyePatternBrightness = (state: RootState) => state.sessionColors.EyeSelectedPatternBrightness;
export const selectEyePatternType = (state: RootState) => state.sessionColors.EyeSelectedPatternType;

// tint selectors ---------->
export const selectTintColorsList = (state: RootState) => state.sessionColors.TintColorsList;
export const selectTintColorNuancesList = (state: RootState) => state.sessionColors.TintColorNuancesList;
export const selectTintColor = (state: RootState) => state.sessionColors.TintSelectedColor;
export const selectTintColorNuances = (state: RootState) => state.sessionColors.TintSelectedColorNuance;