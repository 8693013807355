"use client";

import { useErrorBoundary } from "react-error-boundary";



const AnalysisErrorFallbackComponent = ({ error }: any) => {

    const { resetBoundary } = useErrorBoundary();

    const onClick = () => {
        resetBoundary();
    };
    return (
        <main className="error_rapper">
            <div role="alert" className="error_main_container">
                <p className="error_main_text">{error}</p>
                <p className="error_sub_text">An error occured while executing the analysis !</p>
                <p className="error_sub_text">Please try again, if the error persit, pleas contact the support : contact@magnifiseses.com</p>
                <button className="error_button" onClick={onClick}>Try again</button>
            </div>
        </main>
    );
};

export default AnalysisErrorFallbackComponent;