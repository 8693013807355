import { useRef } from "react";
import { useAppDispatch } from "../../hooks";
import CameraContainer from "../../components/CameraContainer";
import { IStepFlowComponentBase, StepFlowEventType } from "../../types/StepFlowComponent.types";
import Webcam from "react-webcam";
import { resetEyeSessionPhoto, setEyeSessionPhoto } from "../../features/photosManagment/sessionPhotosSlice";

export interface IEyeTakePhotoStepComponentProps extends IStepFlowComponentBase { }

const EyeTakePhotoStepComponent = ({ onComponentEvent }: IEyeTakePhotoStepComponentProps) => {
  const dispatch = useAppDispatch();
  const webcamRef = useRef<null | Webcam>(null);

  const handleCaptureEvent = (screenShot: string | null | undefined) => {
    dispatch(setEyeSessionPhoto(screenShot));
    onComponentEvent({ type: StepFlowEventType.TakePicture });
  };

  const handleBackEvent = () => {
    dispatch(resetEyeSessionPhoto());
    onComponentEvent({ type: StepFlowEventType.Reset });
  };

  return (
    <>
      <CameraContainer
        webCamRef={webcamRef}
        displayFaceFrame={false}
        displayEyeFrame={true}
        hightResolution={true}
        backEvent={handleBackEvent}
        captureEvent={(e) => handleCaptureEvent(e)}
      />
    </>
  );
};

export default EyeTakePhotoStepComponent;
