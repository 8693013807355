import { MagRoutes } from "../../common/MagRoutes";
import { MagSteps } from "../../common/MagSteps";
import {
  IStepFlowDefinition,
  IStepRoute,
} from "../../types/StepFlowComponent.types";
import { IWorkflow } from "../../types/backend.types";

export class WorflowRoutesHelper {
  private readonly _visageStepId = "11111111-1111-1111-1111-100000000000";
  private readonly _workflowRoutes: Map<string, IStepRoute> = new Map([
    [
      "11111111-1111-1111-1111-100000000000",
      { stepName: MagSteps.VISAGE, stepRoute: MagRoutes.photoProfileWorkFlow },
    ],
    [
      "11111111-1111-1111-1111-200000000000",
      { stepName: MagSteps.CHEVEUX, stepRoute: MagRoutes.haireWorkFlow },
    ],
    [
      "11111111-1111-1111-1111-300000000000",
      { stepName: MagSteps.YEUX, stepRoute: MagRoutes.eyeWorkFlow },
    ],
    [
      "11111111-1111-1111-1111-400000000000",
      { stepName: MagSteps.YEUX, stepRoute: MagRoutes.eyePatternWorkFlow },
    ],
    [
      "11111111-1111-1111-1111-500000000000",
      { stepName: MagSteps.TEINT, stepRoute: MagRoutes.tintWorkFlow },
    ],
  ]);

  private readonly _currentSession: IWorkflow;

  constructor(currentSession: IWorkflow) {
    this._currentSession = currentSession;
  }

  public getCurrentStep(): IStepFlowDefinition | undefined {
    const currentStepFlow = this._currentSession.steps?.find(
      (c) => c.isCompleted === false
    );
    if (currentStepFlow) {
      const index = this._currentSession.steps?.indexOf(currentStepFlow);
      return {
        step: currentStepFlow!,
        stepRoute: this._workflowRoutes.get(currentStepFlow!.id!),
        stepIndex: index,
      };
    }
    return undefined;
  }

  public getPhotoProfileId() {
    const step = this._currentSession.steps?.find(
      (s) => s.id === this._visageStepId
    );
    return step?.result?.photoId;
  }
}
