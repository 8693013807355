import { useStepsFlowComponentsManager } from '../../hooks/StepsFlowComponentsManager';
import VideoContainer from '../../components/VideoContainer';
import Register from '../../auth/Register/Register';
import { IStepFlowComponent, IStepFlowEvent, StepFlowEventType } from '../../types/StepFlowComponent.types';

export function StartExperienceWorkFlow() {

  const onComponentEvent = (event: IStepFlowEvent) => {
    if (event.type === StepFlowEventType.skip || event.type === StepFlowEventType.completed)
      handleNext();
  };

  // Define Workflow steps 
  const startExperienceWorkFlow: IStepFlowComponent[] = [
    {
      order: 0,
      component: <VideoContainer videoUrl='../assets/video/Intro.mp4' onComponentEvent={onComponentEvent} />,
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        showCorners: true,
      }
    },
    {
      order: 1,
      component: <Register />,
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        displayLogo: true,
        workFlowProgress: 1,
        showCorners: true,
      }
    },
  ];

  const [activeStepIndex, isCompleted, handleNext] = useStepsFlowComponentsManager({ stepsComponents: startExperienceWorkFlow });

  return (
    <div>
      {startExperienceWorkFlow[activeStepIndex]?.component}
    </div>
  );
}

export default StartExperienceWorkFlow;