import { useEffect } from "react";

import { IStepFlowComponentBase, StepFlowEventType } from "../../types/StepFlowComponent.types";

export interface IResultGenerationStepnProps extends IStepFlowComponentBase { }

const ResultGenerationStep = ({ onComponentEvent }: IResultGenerationStepnProps) => {

  useEffect(() => {
    const processReportAnimation = setTimeout(() => {
      onComponentEvent({ type: StepFlowEventType.saveWorkFlowAndProcessReport });
    }, 2500);

    return () => {
      clearTimeout(processReportAnimation);
    };
  }, []);

  return (
    <div className="process_result_container">
      <h6 className="process_result_text">Géneration de ton profile colométrique en cours</h6>
      <span className="loader"></span>
    </div>
  );
};

export default ResultGenerationStep;
