import { keys } from "lodash";
import { IMagResponseError } from "../types";
import { useState } from "react";

export const useHandleBackendErrors = () => {
  const [backendValidationErrors, setBackendValidationErrors] = useState<
    string[]
  >([]);

  const handleErrors = (error: IMagResponseError) => {
    if (error?.data) {
      const errors = error.data.errors;
      const errorsType = keys(errors);
      let errorMessages: string[] = [];
      errorsType.forEach((key) => {
        errors[key].forEach((err: string) => {
          errorMessages.push(err);
        });
      });
      setBackendValidationErrors(errorMessages);
    } else {
      setBackendValidationErrors(["Something wrong"]);
    }
  };

  return [
    backendValidationErrors,
    setBackendValidationErrors,
    handleErrors,
  ] as const;
};
