import { useState } from "react";
import ColorSelectorContainer from "../../components/ColorSelectorContainer";
import { IStepFlowComponentBase, StepFlowEventType } from "../../types/StepFlowComponent.types";
import { useAppDispatch } from "../../hooks";
import { resetTintSessionPhoto } from "../../features/photosManagment/sessionPhotosSlice";
import TintNuanceSelectionStepActions from "./TintNuanceSelectionStepActions";
import { useSelector } from "react-redux";
import { selectValidatedTintPhoto } from "../../features/photosManagment/sessionPhotosSelectors";
import { setTintSelectedColorNuance, resetTintSelectedColor, resetTintSelectedColorNuance } from "../../features/colorsManagment/sessionColorsSlice";
import { selectTintColor, selectTintColorNuancesList } from "../../features/colorsManagment/sessionColorsSelectors";
import { IColorProfile } from "../../types/backend.types";
import { useTranslation } from "react-i18next";

export interface INuanceColorSelectionStepProps extends IStepFlowComponentBase { }

const TintNuanceColorSelectionStepComponent = ({ onComponentEvent }: INuanceColorSelectionStepProps) => {
  const { t } = useTranslation("common");
  const dispatch = useAppDispatch();
  const TintPicture = useSelector(selectValidatedTintPhoto);
  const SelectedTintColorNuanceList = useSelector(selectTintColorNuancesList);
  const SelectedTintColor = useSelector(selectTintColor);

  const [isColorSelected, setIsColorSelected] = useState<boolean>(false);
  const [colorSelected, setColorSelected] = useState<IColorProfile | null>(null);

  const handleBackEvent = () => {
    dispatch(resetTintSessionPhoto());
    dispatch(resetTintSelectedColor());
    dispatch(resetTintSelectedColorNuance());
    onComponentEvent({ type: StepFlowEventType.Reset });
  };

  const handleColorSelectedEvent = (e: IColorProfile) => {
    setIsColorSelected(true);
    setColorSelected(e);
    dispatch(setTintSelectedColorNuance(e));
  };

  const handleValidateTintNuanceEvent = () => {
    onComponentEvent({ type: StepFlowEventType.SelectNuance, params: [colorSelected] });
  };

  return (
    <>
      <ColorSelectorContainer
        colors={SelectedTintColorNuanceList}
        title={t("tintNuanceSelection.title")}
        selectedColorBackground={{ value: colorSelected?.color, isHexCode: true }}
        picture={TintPicture}
        subTitle={`${t("tintNuanceSelection.subTitle")} ${SelectedTintColor?.name}`}
        backEvent={handleBackEvent}
        selectColorEvent={e => handleColorSelectedEvent(e)}
        actionsComponent={
          <TintNuanceSelectionStepActions colorSelected={isColorSelected} validateTintNuanceEvent={handleValidateTintNuanceEvent} />
        } />

    </>
  );
};
export default TintNuanceColorSelectionStepComponent;
