import { useStepsFlowComponentsManager } from "../../hooks/StepsFlowComponentsManager";
import VideoContainer from "../../components/VideoContainer";
import { IStepFlowComponent, IStepFlowEvent, StepFlowEventType } from "../../types/StepFlowComponent.types";
import { useSelector } from "react-redux";
import PhotoProfileStepComponent from "./photoProfileStep";
import ValidatePhotoProfileStepComponent from "./validatePhotoProfileStep";
import InstructionPageStepContainer from "../../components/InstructionPageStepContainer";
import VisageInstructionStepActions from "./visageInstructionStepActions";
import { selectCurrentSession, selectCurrentStepFLowDefinition } from "../UserWorkFlow/selectors";
import { useAppDispatch } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { useSaveUserPhotoQueryMutation } from "../../features/photosManagment/apiSlice";
import { selectValidatedProfilePhoto } from "../../features/photosManagment/sessionPhotosSelectors";
import { cloneDeep } from "lodash";
import { setPhotoProfileId } from "../../features/photosManagment/sessionPhotosSlice";
import { useTranslation } from "react-i18next";
import { useErrorBoundary } from "react-error-boundary";
import { useSaveUserProgressHelperHook } from "../../hooks/SaveUserProgressHelperHook";

export function PhotoProfileWorkFlow() {
  const { t } = useTranslation("common");
  const { showBoundary } = useErrorBoundary();
  const currentStepFlow = useSelector(selectCurrentStepFLowDefinition);
  const userProgress = useSelector(selectCurrentSession);
  const validatedPhotoProfile = useSelector(selectValidatedProfilePhoto);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [saveProgressAndNavigate, isLoading] = useSaveUserProgressHelperHook(showBoundary);
  const [saveUserPhotoQuery, photoQueryMutation] = useSaveUserPhotoQueryMutation();

  const onComponentEvent = (event: IStepFlowEvent) => {
    switch (event.type) {
      case StepFlowEventType.Reset:
        reset();
        break;
      case StepFlowEventType.skip:
      case StepFlowEventType.completed:
        handleNext();
        break;
      case StepFlowEventType.TakePicture:
        handleNext();
        break;
      case StepFlowEventType.LoadPicture:
        goToStep(3);
        break;
      case StepFlowEventType.GoToStep:
        goToStep(event.params![0].stepIndex);
        break;
      default:
        break;
    }
  };

  const onValidateStepFlowEvent = async () => {
    const _currentStepFlow = cloneDeep(currentStepFlow);
    const _progress = cloneDeep(userProgress);

    try {
      // save profile photo
      const result = await saveUserPhotoQuery(validatedPhotoProfile).unwrap();
      dispatch(setPhotoProfileId(result.id));
      // set step result.
      if (!_currentStepFlow || !_progress)
        return;
      _currentStepFlow.step!.result!.isCompleted = true;
      _currentStepFlow!.step!.result!.photoId = result.id;

      await saveProgressAndNavigate(_progress, _currentStepFlow, dispatch, navigate);
    }
    catch (err: any) { showBoundary('error occurred while saving user photo.'); };

  };
  // Define Workflow steps
  const phtoProfileWorkFlow: IStepFlowComponent[] = [
    {
      order: 0,
      component: (
        <VideoContainer
          videoUrl="../assets/video/Intro.mp4"
          onComponentEvent={onComponentEvent}
        />
      ),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        hideStapper: false,
        showCorners: true
      },
    },
    {
      order: 1,
      component: (
        <InstructionPageStepContainer
          frameNote={currentStepFlow?.step?.frameNote}
          instructionStepImages="face"
          actionsComponent={
            <VisageInstructionStepActions onComponentEvent={onComponentEvent} />
          }
        />
      ),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        displayLogo: false,
        workFlowStep: t('stepper.photo'),
        workFlow: t('stepper.face'),
        workFlowProgress: 1,
        hideStapper: true,
        showCorners: true
      },
    },
    {
      order: 2,
      component: (
        <PhotoProfileStepComponent onComponentEvent={onComponentEvent} />
      ),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        displayLogo: false,
        workFlowStep: t('stepper.photo'),
        workFlow: t('stepper.face'),
        workFlowProgress: 2,
        hideStapper: true,
        showCorners: true
      },
    },
    {
      order: 3,
      component: (
        <ValidatePhotoProfileStepComponent
          onComponentEvent={onComponentEvent}
          validateStepFlowEvent={onValidateStepFlowEvent}
          isLoading={isLoading || photoQueryMutation.isLoading}
        />
      ),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        displayLogo: false,
        workFlowStep: t('stepper.reframe'),
        workFlow: t('stepper.face'),
        workFlowProgress: 3,
        hideStapper: true,
        showCorners: true
      },
    },
  ];

  const [activeStepIndex, , handleNext, reset, goToStep] = useStepsFlowComponentsManager({ stepsComponents: phtoProfileWorkFlow });

  return <div>{phtoProfileWorkFlow[activeStepIndex]?.component}</div>;
}

export default PhotoProfileWorkFlow;
