import { useState } from "react";
import { useTranslation } from "react-i18next";

export interface IVisageInstructionStepActionsProps {
    restartStepEvent: () => void;
    validatePictureEvent: () => void;
}
const ValidatePhotoProfileStepActions = ({ restartStepEvent, validatePictureEvent }: IVisageInstructionStepActionsProps) => {
    const [disableValidateButton, setDisableValidateButton] = useState<boolean>(false);

    const { t } = useTranslation("common");
    const handlePictureValidation = async () => {  
        setDisableValidateButton(true);
        validatePictureEvent();
    };

    const handleRestartStep = () => {
        restartStepEvent();
    };
    return (
        <>
            <button className="recommencer_button" onClick={handleRestartStep}>
                {t("validateFacePhoto.recommencer")}
            </button>
            <button className="valide_button" onClick={handlePictureValidation} disabled={disableValidateButton}>
                {t("validateFacePhoto.valide")}
            </button>
        </>
    );
};
export default ValidatePhotoProfileStepActions;