import { useSelector } from "react-redux";
import {
  IStepFlowComponentBase,
  StepFlowEventType,
} from "../../types/StepFlowComponent.types";
import { useAppDispatch } from "../../hooks";
import UserPictureValidationContainer from "../../components/PictureValidationContainer";
import ValidatePhotoProfileStepActions from "./validatePhotoProfileStepActions";
import {
  resetCurrentSessionPhoto,
  setValidatedProfilePhoto,
} from "../../features/photosManagment/sessionPhotosSlice";
import { selectCurrentSessionPhoto } from "../../features/photosManagment/sessionPhotosSelectors";
import LayoutLoader from "../../components/LayoutLoader";

export interface IValidatePhotoProfileProps extends IStepFlowComponentBase { }

const ValidatePhotoProfileStepComponent = ({
  onComponentEvent,
  validateStepFlowEvent,
  isLoading
}: IValidatePhotoProfileProps) => {
  const currentSessionPhoto = useSelector(selectCurrentSessionPhoto);
  const dispatch = useAppDispatch();

  const handlePictureValidation = () => {
    onComponentEvent({
      type: StepFlowEventType.completed,
    });
    validateStepFlowEvent && validateStepFlowEvent();
  };

  const handleCropperEvent = (e: string) => {
    dispatch(setValidatedProfilePhoto(e));
  };
  const handleRestartStep = () => {
    dispatch(resetCurrentSessionPhoto());
    onComponentEvent({
      type: StepFlowEventType.GoToStep,
      params: [{ stepIndex: 1 }],
    });
  };

  const handleGoBackFunction = () => {
    dispatch(resetCurrentSessionPhoto());
    onComponentEvent({ type: StepFlowEventType.Reset });
  };

  return (
    <>
      {isLoading && <LayoutLoader />}

      <UserPictureValidationContainer
        rounded={false}
        picture={currentSessionPhoto!}
        displayFaceFrame={true}
        backEvent={handleGoBackFunction}
        cropperEvent={(e) => handleCropperEvent(e)}
        actionsComponent={
          <ValidatePhotoProfileStepActions
            restartStepEvent={handleRestartStep}
            validatePictureEvent={handlePictureValidation}
          />
        }
      />
    </>
  );
};
export default ValidatePhotoProfileStepComponent;
