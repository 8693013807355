import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { useStepsFlowComponentsManager } from "../../hooks/StepsFlowComponentsManager";
import VideoContainer from "../../components/VideoContainer";
import { IStepFlowComponent, IStepFlowEvent, StepFlowEventType } from "../../types/StepFlowComponent.types";
import InstructionPageStepContainer from "../../components/InstructionPageStepContainer";
import { selectCurrentSession, selectCurrentStepFLowDefinition } from "../UserWorkFlow/selectors";
import { useAppDispatch } from "../../hooks";
import EyeInstructionsStepAction from "./EyeInstructionsStepAction";
import ValidateEyePhotoStepComponent from "./ValidateEyePhotoStep";
import EyeTakePhotoStepComponent from "./EyePhotoStep";
import EyeColorSelectionPreStepAnimationStep from "./EyeColorSelectionPreAnimationStep";
import EyeColorSelectionStepComponent from "./EyeColorSelectionStepComponent";
import EyeColorSelectionAfterStepAnimationStep from "./EyeColorSelectionAfterStepAnimationStep";
import EyeNuanceColorSelectionStepComponent from "./EyeNuaceColorSelectionStep";
import EyeColorSelectionLastAfterStepAnimationStep from "./EyeNuanceSelectionAfterStepAnimationStep";
import { useGetColorsByColorIdQueryMutation, useGetColorsCategoryByTypeQueryMutation } from "../../features/colorsManagment/apiSlice";
import { setEyeColorsList, setEyeColorNuanceList, setEyeSelectedColor } from "../../features/colorsManagment/sessionColorsSlice";
import { useTranslation } from "react-i18next";
import { useErrorBoundary } from "react-error-boundary";
import EyeTemperatureColorSelectionStepComponent from "./EyeTemperatureColorSelectionStep";
import { IColorCategory } from "../../types/backend.types";
import { useSaveUserProgressHelperHook } from "../../hooks/SaveUserProgressHelperHook";

const EyeWorkFlow = () => {
  const { t } = useTranslation("common");
  const { showBoundary } = useErrorBoundary();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentStepFlow = useSelector(selectCurrentStepFLowDefinition);
  const userProgress = useSelector(selectCurrentSession);
  const [selectedColorId, setSelectedColorId] = useState<string>("");
  const [selectedTemperatureId, setSelectedTemperatureId] = useState<string>("");

  const [saveProgressAndNavigate, isLoading] = useSaveUserProgressHelperHook(showBoundary);
  const [getColorsDataQuery] = useGetColorsCategoryByTypeQueryMutation();
  const [getColorNuanceDataQuery] = useGetColorsByColorIdQueryMutation();

  const getEyeColorsList = async () => {
    const data = await getColorsDataQuery(2);
    if ("data" in data) {
      dispatch(setEyeColorsList(data.data));
    }
  };
  const getEyeColorNuanceList = async (id: string) => {
    const data = await getColorNuanceDataQuery(id);
    if ("data" in data) {
      dispatch(setEyeColorNuanceList(data.data));
    }
  };

  useEffect(() => {
    getEyeColorsList();
    //getEyePatternList();
  }, []);

  useEffect(() => {
    selectedTemperatureId && getEyeColorNuanceList(selectedTemperatureId);
  }, [selectedTemperatureId]);

  const onComponentEvent = (event: IStepFlowEvent) => {
    switch (event.type) {
      case StepFlowEventType.Reset:
        reset();
        break;
      case StepFlowEventType.skip:
      case StepFlowEventType.completed:
        handleNext();
        break;
      case StepFlowEventType.TakePicture:
        goToStep(3);
        break;
      case StepFlowEventType.LoadPicture:
        goToStep(3);
        break;
      case StepFlowEventType.GoToStep:
        goToStep(event.params![0].stepIndex);
        break;
      case StepFlowEventType.SelectColor:
        const _selectedColor: IColorCategory = event.params![0];
        // if color has subColors go to Temperature step, otherwise set selected color and go to nuance step
        if (_selectedColor.subCategories) {
          // temperature step
          goToStep(6);
        } else {
          setSelectedColorId((pre) => (pre = _selectedColor.id!));
          setSelectedTemperatureId((pre) => pre = _selectedColor.id!);
          dispatch(setEyeSelectedColor(_selectedColor));
          goToStep(7);
        }
        break;
      case StepFlowEventType.SelectTemperature:
        const _selectedTemperature: IColorCategory = event.params![0];
        setSelectedTemperatureId((pre) => pre = _selectedTemperature.id!);
        setSelectedColorId((pre) => (pre = _selectedTemperature.id!));
        dispatch(setEyeSelectedColor(_selectedTemperature));
        goToStep(7);
        break;
      case StepFlowEventType.SelectNuance:
        onValidateStepFlowEvent(event.params![0].id);
        break;
      default:
        break;
    }
  };

  const onValidateStepFlowEvent = async (nuanceId: any) => {
    // set step result
    const _currentStepFlow = cloneDeep(currentStepFlow);
    const _progress = cloneDeep(userProgress);
    if (!_currentStepFlow || !_progress) return;

    _currentStepFlow.step!.result!.isCompleted = true;
    _currentStepFlow!.step!.result!.categoryId = selectedColorId;
    _currentStepFlow!.step!.result!.colorId = nuanceId;

    await saveProgressAndNavigate(_progress, _currentStepFlow, dispatch, navigate);
  };

  // Define Workflow steps
  const phtoProfileWorkFlow: IStepFlowComponent[] = [
    {
      order: 0,
      component: (<VideoContainer videoUrl="../assets/video/Intro.mp4" onComponentEvent={onComponentEvent} />),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        hideStapper: false,
        showCorners: true,
      },
    },
    {
      order: 1,
      component: (
        <InstructionPageStepContainer frameNote={currentStepFlow?.step?.frameNote}
          instructionStepImages="eyes"
          actionsComponent={<EyeInstructionsStepAction onComponentEvent={onComponentEvent} />}
        />
      ),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        displayLogo: false,
        workFlowStep: t("stepper.photo"),
        workFlow: t("stepper.eye"),
        workFlowProgress: 6,
        hideStapper: true,
        showCorners: true,
      },
    },
    {
      order: 2,
      component: (
        <EyeTakePhotoStepComponent onComponentEvent={onComponentEvent} />
      ),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        displayLogo: false,
        workFlowStep: t("stepper.reframe"),
        workFlow: t("stepper.eye"),
        workFlowProgress: 7,
        hideStapper: true,
        showCorners: true,
      },
    },
    {
      order: 3,
      component: (
        <ValidateEyePhotoStepComponent onComponentEvent={onComponentEvent} />
      ),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        displayLogo: false,
        workFlowStep: t("stepper.reframe"),
        workFlow: t("stepper.eye"),
        workFlowProgress: 8,
        hideStapper: true,
        showCorners: true,
      },
    },
    {
      order: 4,
      component: (
        <EyeColorSelectionPreStepAnimationStep onComponentEvent={onComponentEvent} />
      ),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        hideStapper: false,
        showCorners: false,
      },
    },
    {
      order: 5,
      component: (
        <EyeColorSelectionStepComponent onComponentEvent={onComponentEvent} />
      ),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        displayLogo: false,
        workFlowStep: t("stepper.color"),
        workFlow: t("stepper.eye"),
        workFlowProgress: 9,
        hideStapper: true,
        showCorners: true,
      },
    },
    {
      order: 6,
      component: (
        <EyeTemperatureColorSelectionStepComponent
          onComponentEvent={onComponentEvent}
          isLoading={isLoading}
        />
      ),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        displayLogo: false,
        workFlowStep: t("stepper.color"),
        workFlow: t("stepper.eye"),
        workFlowProgress: 10,
        hideStapper: true,
        showCorners: true,
      },
    },
    {
      order: 7,
      component: (
        <EyeColorSelectionAfterStepAnimationStep onComponentEvent={onComponentEvent} />
      ),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        hideStapper: false,
        showCorners: false,
      },
    },
    {
      order: 8,
      component: (
        <EyeNuanceColorSelectionStepComponent
          onComponentEvent={onComponentEvent}
          isLoading={isLoading}
        />
      ),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        displayLogo: false,
        workFlowStep: t("stepper.color"),
        workFlow: t("stepper.eye"),
        workFlowProgress: 10,
        hideStapper: true,
        showCorners: true,
      },
    },
    {
      order: 9,
      component: (
        <EyeColorSelectionLastAfterStepAnimationStep onComponentEvent={onComponentEvent} />
      ),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        hideStapper: false,
        showCorners: false,
      },
    },

  ];

  const [activeStepIndex, , handleNext, reset, goToStep] = useStepsFlowComponentsManager({ stepsComponents: phtoProfileWorkFlow });

  return <div>{phtoProfileWorkFlow[activeStepIndex]?.component}</div>;
};

export default EyeWorkFlow;
