import { createSlice } from "@reduxjs/toolkit";
import { ISessionPhotosState } from "./types";

const initialState: ISessionPhotosState = {
  currentSessionPhoto: null,
  eyeSessionPhoto: null,
  tintSessionPhoto: null,
  validatedProfilePhoto: null,
  validatedHairePhoto: null,
  validatedEyePhoto: null,
  validatedTintPhoto: null,
  photoProfileId: null,
};

const sessionPhotosSlice = createSlice({
  name: "sessionPhoto",
  initialState,
  reducers: {
    setCurrentSessionPhoto: (state, action) => {
      state.currentSessionPhoto = action.payload;
    },
    setEyeSessionPhoto: (state, action) => {
      state.eyeSessionPhoto = action.payload;
    },
    setTintSessionPhoto: (state, action) => {
      state.tintSessionPhoto = action.payload;
    },
    setCurrentSessionPhotoAsValidatedHairePhoto: (state) => {
      state.currentSessionPhoto = state.validatedHairePhoto;
    },
    resetCurrentSessionPhoto: (state) => {
      state.currentSessionPhoto = null;
    },
    resetSessionPhoto: (state) => {
      state.currentSessionPhoto = null;
    },
    resetEyeSessionPhoto: (state) => {
      state.eyeSessionPhoto = null;
    },
    resetTintSessionPhoto: (state) => {
      state.tintSessionPhoto = null;
    },
    setValidatedProfilePhoto: (state, action) => {
      state.validatedProfilePhoto = action.payload;
    },
    setValidatedHairePhoto: (state, action) => {
      state.validatedHairePhoto = action.payload;
    },
    setValidatedEyePhoto: (state, action) => {
      state.validatedEyePhoto = action.payload;
    },
    setValidatedTintPhoto: (state, action) => {
      state.validatedTintPhoto = action.payload;
    },
    setPhotoProfileId: (state, action) => {
      state.photoProfileId = action.payload;
    },

    // eyes flow slices ----------->
  },
});

export const {
  setCurrentSessionPhoto,
  setEyeSessionPhoto,
  setTintSessionPhoto,
  resetCurrentSessionPhoto,
  resetEyeSessionPhoto,
  resetTintSessionPhoto,
  setValidatedProfilePhoto,
  setValidatedHairePhoto,
  setValidatedEyePhoto,
  setValidatedTintPhoto,
  setPhotoProfileId,
  setCurrentSessionPhotoAsValidatedHairePhoto,
} = sessionPhotosSlice.actions;
export default sessionPhotosSlice;
