import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_BACKEND_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  credentials: "include",
  mode: "cors",
  /*prepareHeaders: (headers, { getState }) => {
        const state = getState() as RootState
        const token = state.auth.accessToken;
        
        if (token) {
            headers.set("Authorization", `Bearer ${token}`);
        }
        return headers;
    }*/
});

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  let result = await baseQuery(args, api, extraOptions);
  // if (result?.error?.status === 304) {
  //     // send refresh token to get new access token
  //     const refreshResult = await baseQuery('/api/tokens/refresh', api, extraOptions);
  //     if (refreshResult?.data) {
  //         const user = api.getState().auth.user;
  //         // store the new token
  //         api.dispatch(setCredentials({ ...refreshResult.data, user}));
  //         // retry the original query with the new access token
  //         result = await baseQuery(args, api, extraOptions);
  //     } else {
  //         api.dispatch(logOut());
  //     }
  // }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
});
