import { createSlice } from "@reduxjs/toolkit";
import { ISessionColorsState } from "./types";
import { IUserColor } from "../../types/backend.types";

const initialState: ISessionColorsState = {
  // hair initial state ---->
  HairColorsList: [],
  HairColorNuancesList: [],
  HairSelectedColor: null,
  HairSelectedColorNuance: null,

  // eye initial state ----->
  EyeColorsList: [],
  EyeTemperatureList: [],
  EyeColorNuancesList: [],
  EyePatternBrightnessList: [],
  EyePatternTypeList: [],
  EyeSelectedColor: null,
  EyeSelectedColorNuance: null,
  EyeSelectedPatternBrightness: null,
  EyeSelectedPatternType: null,

  // tint initial state ---->
  TintColorsList: [],
  TintColorNuancesList: [],
  TintSelectedColor: null,
  TintSelectedColorNuance: null,
};

const sessionColorsSlice = createSlice({
  name: 'sessionColors',
  initialState,
  reducers: {
    // user colors 
    setUserColors: (state, action) => {
      if(action.payload.eyes) {
        state.EyeSelectedColor = action.payload.eyes ?? null;
        state.EyeSelectedColorNuance = action.payload.eyes ?? null;
      }
      if(action.payload.hair) {
        state.HairSelectedColor = action.payload.hair ?? null;
        state.HairSelectedColorNuance = action.payload.hair ?? null;
      }
      if(action.payload.skin) {
        state.TintSelectedColor = action.payload.skin ?? null;
        state.TintSelectedColorNuance = action.payload.skin ?? null;
      }
    },
    // hair slices ---->
    setHairColorsList: (state, action) => {
      state.HairColorsList = action.payload;
    },
    resetHairColorsList: (state) => {
      state.HairColorsList = [];
    },
    setHairColorNuanceList: (state, action) => {
      state.HairColorNuancesList = action.payload;
    },
    resetHairColorNuanceList: (state) => {
      state.HairColorNuancesList = [];
    },
    setHairSelectedColor: (state, action) => {
      state.HairSelectedColor = action.payload;
    },
    resetHairSelectedColor: (state) => {
      state.HairSelectedColor = null;
    },
    setHairSelectedColorNuance: (state, action) => {
      state.HairSelectedColorNuance = action.payload;
    },
    resetHairSelectedColorNuance: (state) => {
      state.HairSelectedColorNuance = null;
    },

    // eye slices ----->
    setEyeColorsList: (state, action) => {
      state.EyeColorsList = action.payload;
    },
    resetEyeColorsList: (state) => {
      state.EyeColorsList = [];
    },
    setEyeColorNuanceList: (state, action) => {
      state.EyeColorNuancesList = action.payload;
    },
    resetEyeColorNuanceList: (state) => {
      state.EyeColorNuancesList = [];
    },
    setEyePatternBrightnessList: (state, action) => {
      state.EyePatternBrightnessList = action.payload;
    },
    resetEyePatternBrightnessList: (state) => {
      state.EyePatternBrightnessList = [];
    },
    setEyePatternTypeList: (state, action) => {
      state.EyePatternTypeList = action.payload;
    },
    resetEyePatternTypeList: (state) => {
      state.EyePatternTypeList = [];
    },
    setEyeSelectedColor: (state, action) => {
      state.EyeSelectedColor = action.payload;
      if(action.payload.subCategories) 
        state.EyeTemperatureList = action.payload.subCategories;
    },
    resetEyeSelectedColor: (state) => {
      state.EyeSelectedColor = null;
    },
    setEyeSelectedColorNuance: (state, action) => {
      state.EyeSelectedColorNuance = action.payload;
    },
    resetEyeSelectedColorNuance: (state) => {
      state.EyeSelectedColorNuance = null;
    },
    setEyeSelectedPatternBrightness: (state, action) => {
      state.EyeSelectedPatternBrightness = action.payload;
    },
    resetEyeSelectedPatternBrightness: (state) => {
      state.EyeSelectedPatternBrightness = null;
    },
    setEyeSelectedPatternType: (state, action) => {
      state.EyeSelectedPatternType = action.payload;
    },
    resetEyeSelectedPatternType: (state) => {
      state.EyeSelectedPatternType = null;
    },

    // tint slices ---->
    setTintColorsList: (state, action) => {
      state.TintColorsList = action.payload;
    },
    resetTintColorsList: (state) => {
      state.TintColorsList = [];
    },
    setTintColorNuanceList: (state, action) => {
      state.TintColorNuancesList = action.payload;
    },
    resetTintColorNuanceList: (state) => {
      state.TintColorNuancesList = [];
    },
    setTintSelectedColor: (state, action) => {
      state.TintSelectedColor = action.payload;
    },
    resetTintSelectedColor: (state) => {
      state.TintSelectedColor = null;
    },
    setTintSelectedColorNuance: (state, action) => {
      state.TintSelectedColorNuance = action.payload;
    },
    resetTintSelectedColorNuance: (state) => {
      state.TintSelectedColorNuance = null;
    },
  }
});

export const {
  // user colors 
  setUserColors,
  // hair exports ---->
  setHairColorsList,
  resetHairColorsList,
  setHairColorNuanceList,
  resetHairColorNuanceList,
  setHairSelectedColor,
  resetHairSelectedColor,
  setHairSelectedColorNuance,
  resetHairSelectedColorNuance,

  // eye exports ----->
  setEyeColorsList,
  resetEyeColorsList,
  setEyeColorNuanceList,
  resetEyeColorNuanceList,
  setEyeSelectedColor,
  resetEyeSelectedColor,
  setEyeSelectedColorNuance,
  resetEyeSelectedColorNuance,
  setEyePatternBrightnessList,
  resetEyePatternBrightnessList,
  setEyePatternTypeList,
  resetEyePatternTypeList,
  setEyeSelectedPatternBrightness,
  resetEyeSelectedPatternBrightness,
  setEyeSelectedPatternType,
  resetEyeSelectedPatternType,

  // tint exports ---->
  setTintColorsList,
  resetTintColorsList,
  setTintColorNuanceList,
  resetTintColorNuanceList,
  setTintSelectedColor,
  resetTintSelectedColor,
  setTintSelectedColorNuance,
  resetTintSelectedColorNuance
} = sessionColorsSlice.actions;

export default sessionColorsSlice;
