import { useEffect, useState } from "react";

import backArrow from "../assets/image/menu/backArrow.svg";
import userHairImage from "../assets/image/selectColor/hairImg.png";
import imageCover from "../assets/image/selectColor/selectedCover.png";
import { ISelectedBackgroundType, IStepFlowContainerBase } from "../types/StepFlowComponent.types";
import { IColorCategory, IColorProfile } from "../types/backend.types";
import ImageLoader from "./ImageLoader";

export interface IColorSelectorContainerProps extends IStepFlowContainerBase {
  colors: IColorProfile[] | IColorCategory[];
  selectedColorBackground: ISelectedBackgroundType;
  title: string | null | undefined;
  picture: string | null;
  subTitle: string;
  selectColorEvent: (color: IColorProfile) => void;
}

const ColorSelectorContainer = ({ colors, selectedColorBackground, title, picture, subTitle, actionsComponent, selectColorEvent, backEvent }: IColorSelectorContainerProps) => {
  const baseUrl = process.env.REACT_APP_BACKEND_URL;
  const [selectedColorImage, setSelectedColorImage] = useState<string | undefined>(undefined);
  const [selectedColorId, setSelectedColorId] = useState<string | undefined>(undefined);
  const [loaded, setLoaded] = useState(false);

  const handleSelectColor = (color: IColorProfile) => {
    selectColorEvent(color);
    setSelectedColorImage(color.thumbnailUrl);
    setSelectedColorId(color.id);
  };

  const handleGoBackFunction = () => {
    backEvent && backEvent();
  };

  useEffect(() => {
    setLoaded(false);
  }, [selectedColorImage]);

  return (
    <main className="main_select_color_container">
      <button className="back_button" onClick={handleGoBackFunction}>
        <img src={backArrow} alt="" />
        <p>Retour</p>
      </button>

      <section className="main_select_color_rapper">
        <div className="display_color_choice">
          <div className="selected_color" style={{ backgroundColor: `${selectedColorBackground?.isHexCode ? selectedColorBackground.value : ""}` }}>
            {(!loaded && selectedColorImage) && <ImageLoader />}
            {selectedColorImage && <img onLoad={() => setLoaded(true)} src={selectedColorImage ? `${baseUrl}/${selectedColorImage}` : imageCover} alt="" />}
            {!selectedColorId && <div className="white_cover_for_select"></div>}
          </div>

          <div className="user_image">
            <img src={picture ?? userHairImage} alt="" />
          </div>
        </div>

        <div className="colors_selection">
          <h3 className="select_color_header">{title}</h3>
          <p className="select_color_sub_header">
            {subTitle}
          </p>

          <div className="color_selector">
            {colors.map((color: IColorProfile) => (
              <div
                key={color.id}
                className={
                  selectedColorId === color.id
                    ? "color_container active_color"
                    : "color_container"
                }
                onClick={() => handleSelectColor(color)}
                style={color.color && !color.thumbnailUrl ? { backgroundColor: color.color } : {}}
              >
                <img src={(color && color.thumbnailUrl) ? `${baseUrl}/${color.thumbnailUrl}` : ""} alt="" />
                <span
                  className={
                    selectedColorId === color.id
                      ? "color_title avtive_title"
                      : "color_title"
                  }
                >
                  {color.title}
                </span>
              </div>
            ))}
          </div>
        </div>
      </section>

      {actionsComponent}
    </main>
  );
};

export default ColorSelectorContainer;