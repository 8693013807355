import { useEffect } from "react";
import { useProcessColorReportMutation } from "../features/colorsManagment/apiSlice";
import { useFetchProfileQueryMutation } from "../auth/authApiSlice";
import { useAppDispatch } from "../hooks";
import { setUserProfile } from "../auth/authSlice";
import { useNavigate } from "react-router-dom";
import { MagRoutes } from "../common/MagRoutes";
import { useErrorBoundary } from "react-error-boundary";

const ProcessResultComponent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showBoundary } = useErrorBoundary();
  const [processColorReport] = useProcessColorReportMutation();
  const [fetchProfileQuery] = useFetchProfileQueryMutation();

  useEffect(() => {
    processColorReport(null)
      .unwrap()
      .then(() => {
        fetchProfileQuery(null)
          .unwrap()
          .then((profile) => {
            dispatch(setUserProfile(profile));
            navigate(MagRoutes.UserProfileHome);
          })
          .catch(() => showBoundary("error occurred while fetching user profile."));
      }).catch(() => showBoundary("error occurred while prossising user result."));
  }, []);
  return (
    <>
      <h6>géneration de ton profile colométrique en cours ...</h6>
    </>
  );
};

export default ProcessResultComponent;
