import Corners from "../components/Corners";
import errorIcon from "../assets/image/errors/errorIcon.svg";

/** Element to represent error while getting REQUIRED data */
const DataFetchError = () => {

    // TODO error should be displayed in a error-box, screen centred
    return (
        <div className="error_container">
            <Corners />
            <img className="error_icon" src={errorIcon} alt="" />
            <p className="error_text">An error occured while getting data from server, please contact support: <u>contact@magnifissenses.com</u></p>
        </div>
    );
};

export default DataFetchError;