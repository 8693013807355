import { useNavigate, useOutlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectIsAuthenticated } from "./selectors";
import { MagRoutes } from "../common/MagRoutes";
import { useEffect } from "react";

const RouteNonAuthGuard = () => {
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const outlet = useOutlet();
    const navigate = useNavigate();


    useEffect(() => {
        if (isAuthenticated === true) {
            navigate(MagRoutes.UserProfileHome);
        }
    }, [isAuthenticated])

    return (
        <>
            {outlet}
        </>
    );
}

export default RouteNonAuthGuard;