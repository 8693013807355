import { useTranslation } from "react-i18next";
import Corners from "./Corners";
import { useEffect, useState } from "react";


export interface IPreStepAnimationContainerProps {
  top: number | undefined;
  left: number | undefined;
  picture: string;
}
const PreStepAnimationContainer = ({ top, left, picture }: IPreStepAnimationContainerProps) => {
  const { t } = useTranslation("common");
  const [shouldZoom, setShouldZoom] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldZoom(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <main className="recap_animation_container">
      <section className="recap_profile_picture_container">
        <img
          className={`recap_animation_picture ${shouldZoom ? "zoom-in" : ""}`}
          src={picture}
          alt=""
          style={{
            transformOrigin: `${left}% ${top}%`,
            background: `url(${picture})`
          }}
        />

        <div className={`${shouldZoom ? "round_space" : ""} `}></div>

        <div className="recap_text">
          <h3 className="recap_text_h3">{t("stepAnimation.startWith")}</h3>
          <h2 className="recap_text_h2">{t("stepAnimation.color")}</h2>
        </div>
      </section>
    </main>
  );
};

export default PreStepAnimationContainer;
