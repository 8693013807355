const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.crossOrigin = "anonymous";
    image.src = url;
  });

const getRadianAngle = (degreeValue: number): number => {
  return (degreeValue * Math.PI) / 180;
};

const getCroppedImg = async (
  imageSrc: string,
  pixelCrop: any,
  rotation: number = 0
): Promise<string> => {
  const image = await createImage(imageSrc);
  return new Promise<string>((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      reject(new Error("Canvas context is not supported."));
      return;
    }

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    canvas.width = safeArea;
    canvas.height = safeArea;

    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);

    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );

    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    if (!data) {
      reject(new Error("Failed to get image data."));
      return;
    }

    const x = Math.round(0 - safeArea / 2 + image.width * 0.5 - (pixelCrop.x ? pixelCrop.x : 1));
    const y = Math.round(0 - safeArea / 2 + image.height * 0.5 - (pixelCrop.y ? pixelCrop.y : 1));

    const croppedCanvas = document.createElement("canvas");
    const croppedCtx = croppedCanvas.getContext("2d");

    if (!croppedCtx) {
      reject(new Error("Cropped canvas context is not supported."));
      return;
    }

    croppedCanvas.width = pixelCrop.width;
    croppedCanvas.height = pixelCrop.height;

    croppedCtx.putImageData(data, x, y);

    const base64Data = croppedCanvas.toDataURL("image/jpeg", 1.0);
    resolve(base64Data);
  });
};

export default getCroppedImg;
