import { useTranslation } from "react-i18next";

export interface INuanceColorSelectionStepActionsProps {
  colorSelected: boolean;
  validateEyeNuanceEvent: () => void;

}
const EyeNuanceSelectionStepActions = ({ colorSelected, validateEyeNuanceEvent }: INuanceColorSelectionStepActionsProps) => {
  const { t } = useTranslation("common");
  return (
    <>
      <button disabled={!colorSelected} onClick={validateEyeNuanceEvent} className="color_select_button">
        {t("eyeNuanceSelection.validateNuance")}
      </button>

    </>
  );
};
export default EyeNuanceSelectionStepActions;