import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useAppDispatch } from '../../hooks';
import { setCurrentStepFLow } from './userWorkFlowSlice';
import { selectCurrentSession } from './selectors';
import { WorflowRoutesHelper } from './WorflowRoutesHelper';
import { useNavigate, useOutlet } from 'react-router-dom';
import { MagRoutes } from '../../common/MagRoutes';
import { IWorkflow } from '../../types/backend.types';
import { selectUserProfile } from '../../auth/selectors';
import { setUserColors } from '../../features/colorsManagment/sessionColorsSlice';

export function WorkFlowRouter() {
  const outlet = useOutlet();
  const dispatch = useAppDispatch();
  const currentWorkFlowSession = useSelector(selectCurrentSession);
  const userProfile = useSelector(selectUserProfile);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentWorkFlowSession) {
      // workflow completed
      if (currentWorkFlowSession.completedAllSteps === true) {
        const route = userProfile?.isCompleted === true ? MagRoutes.UserProfileHome : MagRoutes.processResult;
        navigate(route);
      } else {
        navigateToNextStep(currentWorkFlowSession);
      }
    }
  }, [currentWorkFlowSession]);

  useEffect(() => {
    userProfile && userProfile.userColors && dispatch(setUserColors(userProfile.userColors));

  }, [userProfile]);

  const navigateToNextStep = (currentWorkFlowSession: IWorkflow) => {
    const wfHelper = new WorflowRoutesHelper(currentWorkFlowSession);
    // get current stepFlow
    const currentStepFlow = wfHelper.getCurrentStep();
    if (currentStepFlow) {
      // set the current StepFlow 
      dispatch(setCurrentStepFLow(currentStepFlow));
      // navigate to StepFlow route
      navigate(currentStepFlow!.stepRoute!.stepRoute!);
    } else {
      console.log("TODO FATAL ERROR");
    }
  }

  return (
    <>
      {outlet}
    </>
  );
}

export default WorkFlowRouter;
