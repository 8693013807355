import { useSelector } from "react-redux";
import { IStepFlowComponentBase, StepFlowEventType } from "../../types/StepFlowComponent.types";
import { useEffect } from "react";
import { selectProfilePhoto } from "../../features/photosManagment/sessionPhotosSelectors";
import AfterStepAnimationContainer from "../../components/AfterStepAnimationContainer";
import { selectTintColor } from "../../features/colorsManagment/sessionColorsSelectors";
import { selectCurrentSession } from "../UserWorkFlow/selectors";
import useImageDimensions from "../../hooks/getImageDimensionsHook";


export interface ITintColorSelectionAfterStepAnimationProps extends IStepFlowComponentBase { }

const TintColorSelectionAfterStepAnimationStep = ({ onComponentEvent }: ITintColorSelectionAfterStepAnimationProps) => {
  const profilePhoto = useSelector(selectProfilePhoto);
  const selectedTintColor = useSelector(selectTintColor);
  const userWorkFlow = useSelector(selectCurrentSession);
  const imageDimensions = useImageDimensions(profilePhoto!);
  const tintAnimationCoordinates = userWorkFlow?.faceInfo?.geometry?.leftCheek;

  const animationOffset = {
    top: 2,
    left: 11
  };

  useEffect(() => {
    const TintAnimation = setTimeout(() => {
      onComponentEvent({ type: StepFlowEventType.completed });
    }, 5000);

    return () => {
      clearTimeout(TintAnimation);
    };
  }, [profilePhoto]);

  return (
    <AfterStepAnimationContainer
      picture={profilePhoto!}
      top={((tintAnimationCoordinates?.top! * 100) / imageDimensions.height!) - animationOffset.top}
      left={((tintAnimationCoordinates?.left! * 100) / imageDimensions.width!) - animationOffset.left}
      nextSubStepName="Nuance"
      colorName={selectedTintColor?.name!}
      colorHexCode={selectedTintColor?.color!} />
  );
};

export default TintColorSelectionAfterStepAnimationStep;
