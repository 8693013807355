import { useState } from "react";
import ColorSelectorContainer from "../../components/ColorSelectorContainer";
import { IStepFlowComponentBase, StepFlowEventType } from "../../types/StepFlowComponent.types";
import HaireColorSelectionStepActions from "./haireColorSelectionStepActions";
import { useAppDispatch } from "../../hooks";
import { resetCurrentSessionPhoto } from "../../features/photosManagment/sessionPhotosSlice";
import { useSelector } from "react-redux";
import { selectValidatedHairePhoto } from "../../features/photosManagment/sessionPhotosSelectors";
import { resetHairSelectedColor, setHairSelectedColor } from "../../features/colorsManagment/sessionColorsSlice";
import { selectHairColorsList } from "../../features/colorsManagment/sessionColorsSelectors";
import { IColorProfile } from "../../types/backend.types";
import { useTranslation } from "react-i18next";

export interface IHaireColorSelectionStepProps extends IStepFlowComponentBase { }

const HaireColorSelectionStepComponent = ({ onComponentEvent }: IHaireColorSelectionStepProps) => {
    const { t } = useTranslation("common");
    const [isColorSelected, setIsColorSelected] = useState<boolean>(false);
    const [colorSelected, setColorSelected] = useState<IColorProfile | null>(null);
    const hairePicture = useSelector(selectValidatedHairePhoto);
    const hairColorsList = useSelector(selectHairColorsList);
    const dispatch = useAppDispatch();

    const handleBackEvent = () => {
        dispatch(resetCurrentSessionPhoto());
        dispatch(resetHairSelectedColor());
        onComponentEvent({ type: StepFlowEventType.Reset });
    };

    const handleColorSelectedEvent = (e: IColorProfile) => {
        setIsColorSelected(true);
        setColorSelected(e);
        dispatch(setHairSelectedColor(e));
    };

    const handleValidateHaireColorEvent = () => {
        onComponentEvent({ type: StepFlowEventType.SelectColor, params: [colorSelected] });
    };

    return (
        <>
            <ColorSelectorContainer
                colors={hairColorsList}
                selectedColorBackground={{ value: "", isHexCode: false }}
                title={isColorSelected ? colorSelected?.name : t("hairColorSelection.title")}
                picture={hairePicture}
                subTitle={t("hairColorSelection.subTitle")}
                backEvent={handleBackEvent}
                selectColorEvent={e => handleColorSelectedEvent(e)}
                actionsComponent={
                    <HaireColorSelectionStepActions
                        colorSelected={isColorSelected}
                        validateHaireColorEvent={handleValidateHaireColorEvent}
                    />
                }
            />

        </>
    );
};
export default HaireColorSelectionStepComponent;