export const getUserBrowserLanguage = () => {
  const storedLanguage = localStorage.getItem('userLanguage');
  if (storedLanguage) {
    return storedLanguage;
  } else {
    localStorage.setItem('userLanguage', "en");
    return localStorage.getItem('userLanguage');
  }
};

export const toggleLanguage = () => {
  const currentLanguage = localStorage.getItem('userLanguage');
  const newLanguage = currentLanguage === 'en' ? 'fr' : 'en';

  localStorage.setItem('userLanguage', newLanguage);
  // eslint-disable-next-line no-restricted-globals
  location.reload();

  return newLanguage;
};
