import { apiSlice } from "../../api/apiSlice";
import { ColorCategory, ColorProfile } from "../../types/backend.types";

export const colorsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getColorsCategoryByTypeQuery: builder.mutation<ColorCategory, any>({
      query: (type: string) => ({
        url: `/color/categories/${type}`,
        method: "GET",
      }),
    }),

    getColorsByColorIdQuery: builder.mutation<ColorProfile, any>({
      query: (colorId: string) => ({
        url: `/color/${colorId}`,
        method: "GET",
      }),
    }),
    processColorReport: builder.mutation({
      query: () => ({
        url: "/colorReport/process",
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetColorsCategoryByTypeQueryMutation,
  useGetColorsByColorIdQueryMutation,
  useProcessColorReportMutation,
} = colorsApiSlice;
