import { useState } from "react";
import { IStepFlowComponentBase, StepFlowEventType } from "../../types/StepFlowComponent.types";

import { useAppDispatch } from "../../hooks";
import { resetEyeSessionPhoto } from "../../features/photosManagment/sessionPhotosSlice";
import { useSelector } from "react-redux";
import { selectValidatedEyePhoto } from "../../features/photosManagment/sessionPhotosSelectors";
import { resetEyeSelectedPatternBrightness, setEyeSelectedPatternBrightness } from "../../features/colorsManagment/sessionColorsSlice";
import { selectEyePatternBrightnessList } from "../../features/colorsManagment/sessionColorsSelectors";
import { IColorProfile } from "../../types/backend.types";
import PatternSelectionComponent from "../../components/PatternSelectionComponent";

export interface IEyePatternSelectionStepProps extends IStepFlowComponentBase { }

const EyePatternCategorySelectionStepComponent = ({ onComponentEvent }: IEyePatternSelectionStepProps) => {
  const dispatch = useAppDispatch();
  const EyePicture = useSelector(selectValidatedEyePhoto);
  const eyePatternBrightnessList = useSelector(selectEyePatternBrightnessList);

  const [selectedPatternCategory, setSelectedPatternCategory] = useState<IColorProfile | null>(null);


  const handleBackEvent = () => {
    dispatch(resetEyeSessionPhoto());
    dispatch(resetEyeSelectedPatternBrightness());
    onComponentEvent({ type: StepFlowEventType.Reset });
  };

  const handlePatternCategorySelectedEvent = (e: IColorProfile) => {
    setSelectedPatternCategory(e);
    // TODO change name to setEyeSelectedPatternCategory 
    dispatch(setEyeSelectedPatternBrightness(e));
  };

  const handleValidatePatternCategoryEvent = () => {
    onComponentEvent({ type: StepFlowEventType.completed, params: [selectedPatternCategory] });
  };

  return (
    <>
      <PatternSelectionComponent
        patternList={eyePatternBrightnessList}
        picture={EyePicture}
        selectedItem={selectedPatternCategory}
        handleBackEvent={handleBackEvent}
        handleValidateEvent={handleValidatePatternCategoryEvent}
        handleSelectEvent={e => handlePatternCategorySelectedEvent(e)}
      />
    </>
  );
};
export default EyePatternCategorySelectionStepComponent;