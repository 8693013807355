export const useEmailValidation = () => {
  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email) || "Invalid email address";
  };

  return {
    validateEmail,
  };
};
