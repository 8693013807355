import { createSlice } from "@reduxjs/toolkit";
import { IHaireStepFlowState } from "./types";

const initialState: IHaireStepFlowState = {
  colorId: undefined,
  nuanceId: undefined,
};

const haireStepFlowSlice = createSlice({
    name: 'haireStepFlow',
    initialState,
    reducers: {
       
    }
});

export const {} = haireStepFlowSlice.actions;
export default haireStepFlowSlice;
