import { useSelector } from "react-redux";
import { IStepFlowComponentBase, StepFlowEventType } from "../types/StepFlowComponent.types";
import Corners from "./Corners";
import { useEffect, useState } from "react";
import { selectProfilePhoto } from "../features/photosManagment/sessionPhotosSelectors";
import {
  selectEyeColor,
  selectEyeColorNuances,
  selectEyePatternBrightness,
  selectHairColor,
  selectHairColorNuances,
  selectTintColor,
  selectTintColorNuances
} from "../features/colorsManagment/sessionColorsSelectors";
import { useTranslation } from "react-i18next";
import { selectCurrentSession } from "../containers/UserWorkFlow/selectors";
import useImageDimensions from "../hooks/getImageDimensionsHook";

export interface IAfterStepAnimationContainerProps extends IStepFlowComponentBase {
  hairDisplayPoints: { top: number; left: number; };
  eyeDisplayPoints: { top: number; right: number; };
  tentDisplayPoints: { top: number; left: number; };
}

const FinalRecapAnimationComponent = ({ onComponentEvent, hairDisplayPoints, eyeDisplayPoints, tentDisplayPoints }: IAfterStepAnimationContainerProps) => {
  const { t } = useTranslation("common");

  const profilePicture = useSelector(selectProfilePhoto);

  const selectedHairColor = useSelector(selectHairColor);
  const selectedHairColorNuance = useSelector(selectHairColorNuances);

  const selectedEyeColor = useSelector(selectEyeColor);
  const selectedEyeColorNuance = useSelector(selectEyeColorNuances);
  const eyeColorPattern = useSelector(selectEyePatternBrightness);

  const SelectTintColor = useSelector(selectTintColor);
  const SelectTintColorNuance = useSelector(selectTintColorNuances);

  const userWorkFlow = useSelector(selectCurrentSession);
  const imageDimensions = useImageDimensions(profilePicture);

  const animationOffset = {
    hair: { top: 27, left: 5 },
    eye: { top: 5, left: 13 },
    tint: { top: 0, left: 13 }
  };

  const [faded, setFaded] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState(false);

  useEffect(() => {
    const faded = setTimeout(() => {
      setFaded(true);
    }, 1000);

    const background = setTimeout(() => {
      setBackgroundColor(true);
    }, 1200);

    const saveProgress = setTimeout(() => {
      onComponentEvent({ type: StepFlowEventType.saveWorkFlowAndProcessReport });
    }, 1800);

    return () => {
      clearTimeout(faded);
      clearTimeout(background);
      clearTimeout(saveProgress);
    };
  });

  return (
    <main className="recap_animation_container">
      <section className="recap_profile_picture_container">
        <img className="recap_animation_picture" src={profilePicture} alt="" />
        {/* hair color display */}
        <div
          className={`animation_result_div ${faded ? "faded_animation" : ""}`}
          style={{
            top: `${((userWorkFlow?.faceInfo?.geometry?.hair?.top! * 100) / imageDimensions.height!) - animationOffset.hair.top}%`,
            left: `${((userWorkFlow?.faceInfo?.geometry?.hair?.left! * 100) / imageDimensions.width!) - animationOffset.hair.left}%`,
          }}
        >
          <div
            className="result_color_top"
            style={{
              transition: backgroundColor ? "all 1s" : "",
              backgroundColor: backgroundColor ? `${selectedHairColor?.color}` : "",
            }}
          ></div>
          <div className="result_color_bottom">
            <p className="result_text">{selectedHairColor?.name}</p>
            <p className="result_subtext">{selectedHairColorNuance?.subfamilly}</p>
          </div>
        </div>

        {/* eye color display */}
        <div
          className={`animation_result_div ${faded ? "faded_animation" : ""}`}
          style={{
            top: `${((userWorkFlow?.faceInfo?.geometry?.rightEye?.top! * 100) / imageDimensions.height!) - animationOffset.eye.top}%`,
            left: `${((userWorkFlow?.faceInfo?.geometry?.rightEye?.right! * 100) / imageDimensions.width!) - animationOffset.eye.left}%`,
          }}
        >
          <div
            className="result_color_top"
            style={{
              transition: backgroundColor ? "all 1s" : "",
              backgroundColor: backgroundColor ? `${selectedEyeColor?.color}` : "",
            }}
          ></div>
          <div className="result_color_bottom">
            <p className="result_text">{selectedEyeColor?.name}</p>
            <p className="result_subtext">{selectedEyeColorNuance?.subfamilly}</p>
            <p className="result_subtext">{eyeColorPattern?.name}</p>
          </div>
        </div>

        {/* tent color display */}
        <div
          className={`animation_result_div ${faded ? "faded_animation" : ""}`}
          style={{
            top: `${((userWorkFlow?.faceInfo?.geometry?.leftCheek?.top! * 100) / imageDimensions.height!)}%`,
            left: `${((userWorkFlow?.faceInfo?.geometry?.leftCheek?.left! * 100) / imageDimensions.width!) - animationOffset.tint.left}%`,
          }}
        >
          <div
            className="result_color_top"
            style={{
              transition: backgroundColor ? "all 1s" : "",
              backgroundColor: backgroundColor ? `${SelectTintColor?.color}` : "",
            }}
          ></div>
          <div className="result_color_bottom">
            <p className="result_text">{SelectTintColor?.name}</p>
            <p className="result_subtext">{SelectTintColorNuance?.fullName}</p>
          </div>
        </div>

        <div className="recap_text_container">
          <h3 className="recap_text_h3">{t("finalRecapAnimation.yourProfile")}</h3>
          <h2 className="recap_text_h2">{t("finalRecapAnimation.colorimetric")}</h2>
        </div>
      </section>
    </main >
  );
};

export default FinalRecapAnimationComponent;