import { ReactNode } from "react";
import { IStepFlow } from "./backend.types";

export interface IStepFlowComponentBase {
  onComponentEvent: (event: IStepFlowEvent) => void;
  validateStepFlowEvent?: () => void;
  isLoading?: boolean;
}

export interface IStepFlowContainerBase {
  actionsComponent: ReactNode;
  backEvent?: () => void;
}

/** representation of what you choose as a background between hexCode and thumbnailUrl */
export interface ISelectedBackgroundType {
  value: string | undefined;
  isHexCode: boolean;
};

/** A front representation of a SubStep of a StepsFLow */
export interface IStepFlowComponent {
  stepperConfiguration: IWorkFlowStepperDefinition;
  order: number;
  component: any;
  isProcessed: boolean;
  componentEvent: (event: IStepFlowEvent) => void;
}

/** Component Events */
/** Event types */
export enum StepFlowEventType {
  Reset,
  completed,
  skip,
  TakePicture,
  LoadPicture,
  GoToStep,
  SelectColor,
  SelectTemperature,
  SelectNuance,
  SelectEyePattern,
  saveWorkFlowAndProcessReport,
}
export interface IStepFlowEvent {
  type: StepFlowEventType;
  params?: any[];
}

export interface IStepRoute {
  stepName: string | undefined;
  stepRoute: string | undefined;
}

/** WorkFlow Definitions  */
export interface IStepFlowDefinition {
  stepRoute: IStepRoute | undefined;
  step: IStepFlow | undefined;
  stepIndex: number | undefined;
}

export interface IWorkFlowStepperDefinition {
  displayLogo?: boolean | null;
  workFlow?: string | null;
  workFlowStep?: string | null;
  workFlowProgress?: number;
  hideStapper?: boolean;
  showCorners?: boolean;
}
