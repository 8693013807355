import { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useValidateRecaptchaTokenQueryMutation } from "../auth/authApiSlice";

export const useValidateRecaptchaHook = () => {
  const [validateRecaptchaTokenQuery, {isLoading}] =
    useValidateRecaptchaTokenQueryMutation();
  const captchaRef = useRef<ReCAPTCHA>(null);
  const [processingRecaptchaValidation, setProcessingRecaptchaValidation] = useState<boolean>();

  const validateRecaptcha = async (): Promise<boolean | undefined> => {
    if (captchaRef) {
      const token = await captchaRef?.current?.executeAsync() ?? "";
      captchaRef?.current?.reset();
      try {
        const result = await validateRecaptchaTokenQuery({ token: token});
        if ("error" in result) return false;
        if ("data" in result) return true;
      } catch (error) {
        return false;
      }
    }else {
      return false;
    }
  };

  useEffect(() => {
    setProcessingRecaptchaValidation(isLoading);
  }, [isLoading]);
  
  return [validateRecaptcha, captchaRef , processingRecaptchaValidation] as const;
};
