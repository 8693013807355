import { useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../hooks";

import { selectValidatedEyePhoto } from "../../features/photosManagment/sessionPhotosSelectors";
import { selectEyePatternTypeList } from "../../features/colorsManagment/sessionColorsSelectors";
import { IStepFlowComponentBase, StepFlowEventType } from "../../types/StepFlowComponent.types";
import { resetEyeSessionPhoto } from "../../features/photosManagment/sessionPhotosSlice";

import { resetEyeSelectedColorNuance, resetEyeSelectedPatternBrightness, resetEyeSelectedPatternType, setEyeSelectedPatternType } from "../../features/colorsManagment/sessionColorsSlice";
import { IColorProfile } from "../../types/backend.types";
import PatternSelectionComponent from "../../components/PatternSelectionComponent";
import LayoutLoader from "../../components/LayoutLoader";

export interface IEyePatternSelectionStepComponentProps extends IStepFlowComponentBase { }

const EyePatternSelectionStepComponent = ({ onComponentEvent, isLoading }: IEyePatternSelectionStepComponentProps) => {
  const dispatch = useAppDispatch();

  const EyePicture = useSelector(selectValidatedEyePhoto);
  const eyePatternTypeList = useSelector(selectEyePatternTypeList);

  const [selectedPattern, setSelectedPattern] = useState<IColorProfile | null>(null);

  const handleGoBackReset = () => {
    dispatch(resetEyeSelectedPatternType());
    dispatch(resetEyeSelectedColorNuance());
    dispatch(resetEyeSelectedPatternBrightness());
    dispatch(resetEyeSessionPhoto());
    onComponentEvent({ type: StepFlowEventType.Reset });
  };

  const handleSelectColor = (color: IColorProfile) => {
    setSelectedPattern(color);
    dispatch(setEyeSelectedPatternType(color));
  };

  const handleValidatePatternEvent = () => {
    onComponentEvent({
      type: StepFlowEventType.SelectEyePattern,
      params: [selectedPattern]
    });
  };

  return (
    <>
      {isLoading && <LayoutLoader />}
      <PatternSelectionComponent
        patternList={eyePatternTypeList}
        picture={EyePicture}
        selectedItem={selectedPattern}
        handleBackEvent={handleGoBackReset}
        handleSelectEvent={handleSelectColor}
        handleValidateEvent={handleValidatePatternEvent}
      />
    </>
  );
};

export default EyePatternSelectionStepComponent;