import { useState } from "react";
import ColorSelectorContainer from "../../components/ColorSelectorContainer";
import { IStepFlowComponentBase, StepFlowEventType } from "../../types/StepFlowComponent.types";
import TintColorSelectionStepActions from "./TintColorSelectionStepActions";
import { useAppDispatch } from "../../hooks";
import { resetTintSessionPhoto } from "../../features/photosManagment/sessionPhotosSlice";
import { useSelector } from "react-redux";
import { selectValidatedTintPhoto } from "../../features/photosManagment/sessionPhotosSelectors";
import { resetTintSelectedColor, setTintSelectedColor } from "../../features/colorsManagment/sessionColorsSlice";
import { selectTintColorsList } from "../../features/colorsManagment/sessionColorsSelectors";
import { IColorProfile } from "../../types/backend.types";
import { useTranslation } from "react-i18next";

export interface ITintColorSelectionStepProps extends IStepFlowComponentBase { };

const TintColorSelectionStepComponent = ({ onComponentEvent }: ITintColorSelectionStepProps) => {
  const { t } = useTranslation("common");
  const dispatch = useAppDispatch();
  const TintPicture = useSelector(selectValidatedTintPhoto);
  const tintColorList = useSelector(selectTintColorsList);

  const [isColorSelected, setIsColorSelected] = useState<boolean>(false);
  const [colorSelected, setColorSelected] = useState<IColorProfile | null>(null);

  const handleBackEvent = () => {
    dispatch(resetTintSessionPhoto());
    dispatch(resetTintSelectedColor());
    onComponentEvent({ type: StepFlowEventType.Reset });
  };

  const handleColorSelectedEvent = (e: IColorProfile) => {
    setIsColorSelected(true);
    setColorSelected(e);
    dispatch(setTintSelectedColor(e));
  };

  const handleValidateTintColorEvent = () => {
    onComponentEvent({ type: StepFlowEventType.SelectColor, params: [colorSelected] });
  };

  return (
    <>
      <ColorSelectorContainer
        colors={tintColorList}
        selectedColorBackground={{ value: "", isHexCode: false }}
        title={isColorSelected ? colorSelected?.name : t("tintColorSelection.title")}
        picture={TintPicture}
        subTitle={t("tintColorSelection.subTitle")}
        backEvent={handleBackEvent}
        selectColorEvent={e => handleColorSelectedEvent(e)}
        actionsComponent={
          <TintColorSelectionStepActions colorSelected={isColorSelected} validateTintColorEvent={handleValidateTintColorEvent} />
        }
      />
    </>
  );
};

export default TintColorSelectionStepComponent;