import { useState } from "react";
import { IStepFlowContainerBase } from "../types/StepFlowComponent.types";
import { IFrameNote } from "../types/backend.types";
import SkeletonComponent from "./SkeletonComponent";

import validMark from "../assets/image/instructions/check.svg";
import invalidMark from "../assets/image/instructions/wrong.svg";
import faceImage01 from "../assets/image/instructions/face/visage_1.png";
import faceImage02 from "../assets/image/instructions/face/visage_2.png";
import faceImage03 from "../assets/image/instructions/face/visage_3.png";
import faceImage04 from "../assets/image/instructions/face/visage_4.png";

import hairImage01 from "../assets/image/instructions/hair/cheveux_1.png";
import hairImage02 from "../assets/image/instructions/hair/cheveux_2.png";
import hairImage03 from "../assets/image/instructions/hair/cheveux_3.png";
import hairImage04 from "../assets/image/instructions/hair/cheveux_4.png";

import eyeImage01 from "../assets/image/instructions/eyes/yeux_01.png";
import eyeImage02 from "../assets/image/instructions/eyes/yeux_2.png";
import eyeImage03 from "../assets/image/instructions/eyes/yeux_3.png";

import taintImage01 from "../assets/image/instructions/skin/skintone_1.png";
import taintImage02 from "../assets/image/instructions/skin/skintone_2.png";
import taintImage03 from "../assets/image/instructions/skin/skintone_3.png";
import taintImage04 from "../assets/image/instructions/skin/skintone_4.png";

import patternImage01 from "../assets/image/instructions/pattern/iris_01.png";
import patternImage02 from "../assets/image/instructions/pattern/iris_02.png";
import patternImage03 from "../assets/image/instructions/pattern/iris_03.png";


export interface IInstructionPageStepContainerProps extends IStepFlowContainerBase {
  frameNote?: IFrameNote | undefined;
  instructionStepImages: string | undefined;
}
const InstructionPageStepContainer = ({
  frameNote,
  actionsComponent,
  instructionStepImages
}: IInstructionPageStepContainerProps) => {
  const [loadingImage, setLoadingImage] = useState(false);
  const faceImagesArray = [faceImage01, faceImage02, faceImage03, faceImage04];
  const hairImagesArray = [hairImage01, hairImage02, hairImage03, hairImage04];
  const eyeImagesArray = [eyeImage01, eyeImage02, eyeImage03];
  const taintImagesArray = [taintImage01, taintImage02, taintImage03, taintImage04];
  const patternImagesArray = [patternImage01, patternImage02, patternImage03];

  const selectInstructionImages = () => {
    switch (instructionStepImages) {
      case "face":
        return faceImagesArray;
      case "hair":
        return hairImagesArray;
      case "eyes":
        return eyeImagesArray;
      case "skin":
        return taintImagesArray;
      case "pattern":
        return patternImagesArray;
      default:
        return faceImagesArray;
    }
  };

  return (
    <main className="First_instruction_page_container">
      <section className="fip_section">
        {!loadingImage &&
          <div className="fip_images">
            <SkeletonComponent />
            <SkeletonComponent />
            <SkeletonComponent />
            <SkeletonComponent />
          </div>
        }

        <div onLoad={() => setLoadingImage(true)} className="fip_images">
          {
            selectInstructionImages().map((image, i) => (
              <img key={i} src={image} alt="" className="fip_image" style={{ display: loadingImage ? "block" : "none" }} />
            ))
          }
        </div>

        <div className="instruction_container">
          <ul className="valide_instructions">
            {frameNote && frameNote.prescriptions && frameNote.prescriptions?.map((prescription, i) => {
              return (
                <div className="valide_instruction" key={i}>
                  <img src={validMark} alt="valid Mark" />
                  <li className="valide_instruction_p" > {prescription}</li>
                </div>
              );
            })}
          </ul>

          <ul className="invalide_instructions">
            {frameNote && frameNote.proscriptions && frameNote.proscriptions?.map((proscription, i) => {
              return (
                <div className="valide_instruction" key={i}>
                  <img src={invalidMark} alt="valid Mark" />
                  <li className="invalide_instruction_p"> {proscription}</li>
                </div>
              );
            })}
          </ul>
        </div>
        {actionsComponent}
      </section>
    </main>
  );
};

export default InstructionPageStepContainer;
