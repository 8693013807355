import { useEffect, useState } from "react";
import { IStepFlowComponent } from "../types/StepFlowComponent.types";
import { useAppDispatch } from "../hooks";
import { setStepperConfiguration } from "../containers/UserWorkFlow/userWorkFlowSlice";

interface IStepManagerHookProps {
  stepsComponents: IStepFlowComponent[];
}

export const useStepsFlowComponentsManager = ({ stepsComponents }: IStepManagerHookProps) => {
  const dispatch = useAppDispatch();

  // array of setps components
  const [steps, setSteps] = useState<IStepFlowComponent[]>(stepsComponents.sort((a, b) => a.order - b.order));

  // current step
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);

  // current steps are completed
  const [isCompleted, setIsCompleted] = useState<boolean>(false);

  // Go to the next step
  const handleNext = () => {
    setProcessed(activeStepIndex);
    const nextStep = steps.find((i) => !i.isProcessed);
    if (nextStep) {
      setActiveStepIndex((pr) => (pr = nextStep.order));      
    } else {
      setIsCompleted((pr) => (pr = true));
    }
  };

  // Go to the next step
  const goToStep = (stepIndex: number) => {
    resetSteps();
    // set all steps before stepIndex processed true
    for (let i = 0; i <= stepIndex; i++) {
      steps[i].isProcessed = true;
    }
    setActiveStepIndex(stepIndex);
  };

  // Go to the next step
  const reset = () => {
    resetSteps();
    setActiveStepIndex(0);
  };

  // Set the current step processed
  const setProcessed = (order: number) => {
    const step = steps.find((i) => i.order === order);
    if (step) step.isProcessed = true;
  };

  const resetSteps = () => {
    const _steps = steps;
    _steps.map((i) => (i.isProcessed = false));
    setSteps(_steps);
  };

  useEffect(() => {
    if (steps) {
      const navConfig = steps[activeStepIndex];
      // dispatch the configuration of the current subStep
      dispatch(setStepperConfiguration(navConfig.stepperConfiguration));
    }
  }, [activeStepIndex]);

  return [activeStepIndex, isCompleted, handleNext, reset, goToStep] as const;
};
