import { useEffect } from "react";
import { useSelector } from "react-redux";

import PreStepAnimationContainer from "../../components/PreStepAnimationContainer";
import { IStepFlowComponentBase, StepFlowEventType } from "../../types/StepFlowComponent.types";
import { selectProfilePhoto } from "../../features/photosManagment/sessionPhotosSelectors";
import { selectCurrentSession } from "../UserWorkFlow/selectors";
import useImageDimensions from "../../hooks/getImageDimensionsHook";

export interface IEyeColorSelectionPreStepAnimationProps extends IStepFlowComponentBase { }

const EyeColorSelectionPreStepAnimationStep = ({ onComponentEvent }: IEyeColorSelectionPreStepAnimationProps) => {
  const profilePhoto = useSelector(selectProfilePhoto);
  const userWorkFlow = useSelector(selectCurrentSession);
  const pictureDimensions = useImageDimensions(profilePhoto!);
  const eyeAnimationCoordinates = userWorkFlow?.faceInfo?.geometry?.rightEye;

  const animationOffset = {
    top: 5,
    right: 7
  };

  useEffect(() => {
    const EyeAnimation = setTimeout(() => {
      onComponentEvent({ type: StepFlowEventType.completed });
    }, 5000);

    return () => {
      clearTimeout(EyeAnimation);
    };
  }, [profilePhoto]);

  return (
    <PreStepAnimationContainer
      picture={profilePhoto!}
      top={((eyeAnimationCoordinates?.top! * 100) / pictureDimensions.height!) + animationOffset.top}
      left={((eyeAnimationCoordinates?.right! * 100) / pictureDimensions.width!) + animationOffset.right}
    />
  );
};

export default EyeColorSelectionPreStepAnimationStep;
