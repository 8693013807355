import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectIsAuthenticated } from "./selectors";
import { MagRoutes } from "../common/MagRoutes";
import { useVerifyPayementQueryMutation } from "./authApiSlice";
import { IVerifyPaymentResponse } from "./types";
import { useErrorBoundary } from "react-error-boundary";
import { useEffect } from "react";
import { useAppDispatch } from "../hooks";
import { useGetUserProgressQueryMutation } from "../containers/UserWorkFlow/userWorkflowApiSlice";
import { setWorkflowSession } from "../containers/UserWorkFlow/userWorkFlowSlice";

const SubscriptionRouteGuard = () => {
    const [verifyPayementQuery] = useVerifyPayementQueryMutation();
    const [getUserProgressQuery] = useGetUserProgressQueryMutation();
    const navigate = useNavigate();
    const { showBoundary } = useErrorBoundary();
    const dispatch = useAppDispatch();
    const isAuthenticated = useSelector(selectIsAuthenticated);

    const checkUserSubscription = (payement: IVerifyPaymentResponse) => {
        if (!payement) {
            showBoundary("an error occured while checking your subscription !");
        }
        if (!payement.sucess)
            navigate(MagRoutes.paymentForm);
    }

    useEffect(() => {
        const checkRequiredData = async () => {
            try {
                // check if user has subscription
                const payment = await verifyPayementQuery(null).unwrap();
                checkUserSubscription(payment);
                if (payment.sucess) {
                    const userProgress = await getUserProgressQuery(null).unwrap();
                    dispatch(setWorkflowSession(userProgress));
                }
            } catch (err) {
                showBoundary(err);
            }
        };

        if (!isAuthenticated) {
             navigate(MagRoutes.Index, { replace: true });
        } else {
            checkRequiredData();
        }
    }, [dispatch, getUserProgressQuery, showBoundary, verifyPayementQuery]);

    return (
        <>
            <Outlet />
        </>
    );
}

export default SubscriptionRouteGuard;