import { useEffect, useRef, useState, useTransition } from "react";
import videoIcon from "../assets/image/video/videoIcon.svg";
import nextIcon from "../assets/image/video/nextIcon.svg";
import {
  IStepFlowComponentBase,
  StepFlowEventType,
} from "../types/StepFlowComponent.types";
import { useTranslation } from "react-i18next";

export interface IWelocmVideoProps extends IStepFlowComponentBase {
  videoUrl: string;
}

const VideoContainer = ({ onComponentEvent }: IWelocmVideoProps) => {
  const { t } = useTranslation("common");
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const vid = require("../assets/video/Intro.mp4");

  useEffect(() => {
    const startVideo = () => {
      if (videoRef.current) {
        const playPromise = videoRef.current.play();
        playPromise !== undefined && playPromise.then(() => { }).catch(() => { });
      }
    };

    const timeoutId = setTimeout(startVideo, 3000);

    return () => clearTimeout(timeoutId);
  }, []);

  const handleVideoFinished = () => {
    onComponentEvent({ type: StepFlowEventType.completed });
  };
  const handleSkipVideo = () => {
    onComponentEvent({ type: StepFlowEventType.skip });
  };

  const handlePlayVideo = () => {
    setIsPlaying(true);
    videoRef.current?.play();
  };

  return (
    <main className="video_container">
      <section className="video_adapter">
        <video
          ref={videoRef}
          className="video"
          onEnded={handleVideoFinished}
          onPlay={handlePlayVideo}
        >
          <source src={vid} type="video/mp4" />
        </video>
        {!isPlaying && (
          <>
            <div className="video_controls">
              <h2 className="video_header">{t("videoContainer.title")}</h2>
              <button className="play_button" onClick={handlePlayVideo}>
                <img src={videoIcon} alt="" />
                <p>{t("videoContainer.startVideo")}</p>
              </button>
            </div>
            <div className="dark_layout"></div>
          </>
        )}
        <footer className="welcome_video_footer">
          <p className="welcome_video_text">
            {t("videoContainer.videoMessage")}
          </p>

          <button className="next_button" onClick={handleSkipVideo}>
            <p>{t("videoContainer.nextPage")}</p>
            <img src={nextIcon} alt="" />
          </button>
        </footer>
      </section>
    </main>
  );
};

export default VideoContainer;
