import { assign, findIndex } from "lodash";
import { IStepFlowDefinition } from "../types/StepFlowComponent.types";
import { IWorkflow } from "../types/backend.types";
import { MagRoutes } from "../common/MagRoutes";
import { useSaveUserProgressQueryMutation } from "../containers/UserWorkFlow/userWorkflowApiSlice";
import { setWorkflowSession } from "../containers/UserWorkFlow/userWorkFlowSlice";

export const useSaveUserProgressHelperHook = (showBoundary: any) => {

  const [saveUserProgressQuery, { isLoading }] = useSaveUserProgressQueryMutation();

  const updateCurrentStep = (progress: IWorkflow, currentStepFlow: IStepFlowDefinition, ) => {
    const index = findIndex(progress.steps, { id: currentStepFlow.step?.id });
    if (index !== -1 && progress.steps) {
      assign(progress.steps[index], currentStepFlow.step);
    }
  };

  const saveProgressAndNavigate = async (
    progress: IWorkflow,
    currentStepFlow: IStepFlowDefinition,
    dispatch: any,
    navigate: (route: string) => void
  ) => {
    try {
      updateCurrentStep(progress, currentStepFlow);
      const result = await saveUserProgressQuery(progress).unwrap();
      dispatch(setWorkflowSession(result));
      navigate(MagRoutes.analysisRouter);
    } catch (error) {
      showBoundary('Error occurred while saving user pattern.');
    }
  };

  return [
    saveProgressAndNavigate,
    isLoading
  ] as const;
};
