import { useState, useEffect } from 'react';

const useImageDimensions = (imageSrc: string) => {
  const [dimensions, setDimensions] = useState<{ width: number | undefined, height: number | undefined; }>({ width: undefined, height: undefined });
  const image = new Image();
  image.src = imageSrc;

  useEffect(() => {
    image.onload = () => setDimensions({ width: image.width, height: image.height });

    return () => {
      image.onload = null;
    };
  }, []);

  return dimensions;
};

export default useImageDimensions;
