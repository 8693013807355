import { useTranslation } from "react-i18next";
import {
  IStepFlowComponentBase,
  StepFlowEventType,
} from "../../types/StepFlowComponent.types";

export interface ITintInstructionStepActionsProps
  extends IStepFlowComponentBase { }

const TintInstructionStepActions = ({ onComponentEvent }: ITintInstructionStepActionsProps) => {
  const { t } = useTranslation("common");
  const handleValidateInstruction = () => {
    onComponentEvent({ type: StepFlowEventType.completed });
  };

  return (
    <div className="instruction_page_buttons">
      <button className="take_pic" onClick={handleValidateInstruction}>
        {t("instructionActions.IUnderstand")}
      </button>
    </div>
  );
};
export default TintInstructionStepActions;
