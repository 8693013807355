import { apiSlice } from "../../api/apiSlice";
import { ISavePhotoResult } from "./types";
import { Buffer } from "buffer";

export const usePhotoFeatureApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    saveUserPhotoQuery: builder.mutation<ISavePhotoResult, any>({
      query: (model: string) => {
        // Decode base64 string using Buffer
        const base64 = model.split(",")[1];
        const decodedImage = Buffer.from(base64, "base64");
        // Create Blob
        const blob = new Blob([decodedImage], { type: "image/jpeg" }); 
        return {
          url: "/photo/save",
          method: "POST",
          body: blob,
        };
      },
    }),
    getPhotoByIdQuery: builder.mutation<any, any>({
      query: (id: string) => ({
        url: `/photo/fetch/${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useSaveUserPhotoQueryMutation, useGetPhotoByIdQueryMutation } =
  usePhotoFeatureApiSlice;
