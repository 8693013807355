import { Suspense, useEffect } from "react";
import { useOutlet } from "react-router-dom";
import { useAppDispatch } from "../hooks";
import { setUser, setUserProfile } from "./authSlice";
import { useCheckAuthQueryMutation, useFetchProfileQueryMutation } from "./authApiSlice";
import { selectIsAuthenticated } from "./selectors";
import { useSelector } from "react-redux";
import { setPhotoProfileId } from "../features/photosManagment/sessionPhotosSlice";
import LayoutLoader from "../components/LayoutLoader";

export const AuthLayout = () => {
  const outlet = useOutlet();

  //const { data } = useLoaderData() as any;
  const [fetchProfileQuery] = useFetchProfileQueryMutation();

  const [checkAuth] = useCheckAuthQueryMutation();
  const dispatch = useAppDispatch();

  const fetchRequiredData = async () => {
    const profile = await fetchProfileQuery(null).unwrap();
    dispatch(setUserProfile(profile));
    dispatch(setPhotoProfileId(profile.pictureId));
  };

  const initializeUserRequiredData = async () => {
    const user = await checkAuth(null).unwrap();
    if (user) {
      dispatch(
        dispatch(setUser({ emailAddress: user.emailAddress, userId: user.userId, isAuthenticated: user.isAuthenticated }))
      );
      if (user.isAuthenticated === true) {
        await fetchRequiredData();
      }
    }
    return false;
  }

  useEffect(() => {
    initializeUserRequiredData();
  }, [dispatch]);

  return (
    <>
      <Suspense fallback={<LayoutLoader />}>
        {outlet}
      </Suspense>
    </>
  );
};