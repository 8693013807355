import { createSlice } from "@reduxjs/toolkit";
import { IUserWorkFlowState } from "./types";

const initialState: IUserWorkFlowState = {
  session: null,
  currentStepFLowDefinition: null,
  stepperConfiguration: null,
};

const userWorkFlowSlice = createSlice({
  name: "userWorkFlow",
  initialState,
  reducers: {
    setWorkflowSession: (state, action) => {
      state.session = action.payload;
    },
    setCurrentStepFLow: (state, action) => {
      state.currentStepFLowDefinition = action.payload;
    },
    setStepperConfiguration: (state, action) => {
      state.stepperConfiguration = action.payload;
    },
  },
});

export const {
  setWorkflowSession,
  setCurrentStepFLow,
  setStepperConfiguration,
} = userWorkFlowSlice.actions;
export default userWorkFlowSlice;
