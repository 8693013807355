import { useTranslation } from "react-i18next";

export interface IEyeColorSelectionStepActionsProps {
  colorSelected: boolean;
  validateEyeColorEvent: () => void;

}
const EyeColorSelectionStepActions = ({ colorSelected, validateEyeColorEvent }: IEyeColorSelectionStepActionsProps) => {
  const { t } = useTranslation("common");
  return (
    <>
      <button disabled={!colorSelected} onClick={validateEyeColorEvent} className="color_select_button">
        {t("eyeColorSelection.validateColor")}
      </button>
    </>
  );
};

export default EyeColorSelectionStepActions;