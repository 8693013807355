import { useTranslation } from "react-i18next";

export interface IValidateHairePhotoStepActionsProps {
    takeAnOtherPhotoEvent: () => void;
    validatePictureEvent: () => void;
}
const ValidateHairePhotoStepActions = ({ takeAnOtherPhotoEvent, validatePictureEvent }: IValidateHairePhotoStepActionsProps) => {
    const { t } = useTranslation("common");
    const handlePictureValidation = async () => {
        validatePictureEvent();
    };

    const handleTakeAnOtherPhoto = () => {
        takeAnOtherPhotoEvent();
    };
    return (
        <>
            <button className="recommencer_button" onClick={handleTakeAnOtherPhoto}>
                {t("validatePhoto.takeAnotherPicture")}
            </button>
            <button className="valide_button" onClick={handlePictureValidation}>
                {t("validatePhoto.validatePicture")}
            </button>
        </>
    );
};
export default ValidateHairePhotoStepActions;