import { useStepsFlowComponentsManager } from "../../hooks/StepsFlowComponentsManager";
import VideoContainer from "../../components/VideoContainer";
import { IStepFlowComponent, IStepFlowDefinition, IStepFlowEvent, StepFlowEventType } from "../../types/StepFlowComponent.types";
import { useSelector } from "react-redux";
import { selectCurrentSession, selectCurrentStepFLowDefinition } from "../UserWorkFlow/selectors";

import { useEffect, useState } from "react";
import { assign, cloneDeep, findIndex } from "lodash";
import { useSaveUserProgressQueryMutation } from "../UserWorkFlow/userWorkflowApiSlice";
import { setWorkflowSession } from "../UserWorkFlow/userWorkFlowSlice";
import { useAppDispatch } from "../../hooks";
import InstructionPageStepContainer from "../../components/InstructionPageStepContainer";
import TintInstructionStepActions from "./TintInstructionPageStepActionComponent";
import ValidateTintPhotoStepComponent from "./ValidateTintPhotoStep";
import TintTakePhotoStepComponent from "./TintTakePhotoStepComponent";
import TintColorSelectionPreStepAnimationStep from "./TintColorSelectionPreStepAnimationStep";
import TintColorSelectionStepComponent from "./TintColorSelectionStepComponent";
import TintColorSelectionAfterStepAnimationStep from "./TintColorSelectionAfterStepAnimationStep";
import TintNuanceColorSelectionStepComponent from "./TintNuanceColorSelectionStepComponent";
import FinalRecapAnimationComponent from "../../components/FinalRecapAnimationComponent";
import { useGetColorsByColorIdQueryMutation, useGetColorsCategoryByTypeQueryMutation, useProcessColorReportMutation } from "../../features/colorsManagment/apiSlice";
import { setTintColorsList, setTintColorNuanceList, setTintSelectedColor } from "../../features/colorsManagment/sessionColorsSlice";
import { selectTintColor } from "../../features/colorsManagment/sessionColorsSelectors";
import { selectValidatedTintPhoto } from "../../features/photosManagment/sessionPhotosSelectors";
import { useFetchProfileQueryMutation } from "../../auth/authApiSlice";
import { setUserProfile } from "../../auth/authSlice";
import ResultGenerationStep from "./ResultGenerationStep";
import { useTranslation } from "react-i18next";
import { useErrorBoundary } from "react-error-boundary";
import { useSaveUserPhotoQueryMutation } from "../../features/photosManagment/apiSlice";
import { IWorkflow } from "../../types/backend.types";


const TintWorkFlow = () => {
  const { t } = useTranslation("common");
  const { showBoundary } = useErrorBoundary();
  const dispatch = useAppDispatch();
  const currentStepFlow = useSelector(selectCurrentStepFLowDefinition);
  const userProgress = useSelector(selectCurrentSession);
  const selectedTintColor = useSelector(selectTintColor);
  const validatedTintPhoto = useSelector(selectValidatedTintPhoto);
  const [saveUserProgressQuery] = useSaveUserProgressQueryMutation();
  const [fetchProfileQuery] = useFetchProfileQueryMutation();
  const [processColorReport] = useProcessColorReportMutation();
  const [saveUserPhotoQuery, photoQueryMutation] = useSaveUserPhotoQueryMutation();
  const [getColorsDataQuery] = useGetColorsCategoryByTypeQueryMutation();
  const [getColorNuanceDataQuery] = useGetColorsByColorIdQueryMutation();
  const [selectedColorId, setSelectedColorId] = useState<string>("");
  const [selectedNuanceId, setSelectedNuanceId] = useState<string>("");

  const getHairColorsData = async () => {
    const data = await getColorsDataQuery(3);
    if ("data" in data) {
      dispatch(setTintColorsList(data.data));
    }
  };

  const getHiarColorNuanceData = async (id: string) => {
    const data = await getColorNuanceDataQuery(id);
    if ("data" in data) {
      dispatch(setTintColorNuanceList(data.data));
    }
  };

  useEffect(() => {
    getHairColorsData();
  }, []);

  useEffect(() => {
    selectedTintColor?.id && getHiarColorNuanceData(selectedTintColor.id);
  }, [selectedTintColor]);

  const onComponentEvent = (event: IStepFlowEvent) => {
    switch (event.type) {
      case StepFlowEventType.Reset:
        reset();
        break;
      case StepFlowEventType.skip:
      case StepFlowEventType.completed:
        handleNext();
        break;
      case StepFlowEventType.TakePicture:
        goToStep(2);
        break;
      case StepFlowEventType.LoadPicture:
        goToStep(3);
        break;
      case StepFlowEventType.GoToStep:
        goToStep(event.params![0].stepIndex);
        break;

      // specific Tint step event 
      case StepFlowEventType.SelectColor:
        setSelectedColorId((pre) => pre = event.params![0].id);
        dispatch(setTintSelectedColor(event.params![0]));
        handleNext();
        break;
      case StepFlowEventType.SelectNuance:
        setSelectedNuanceId(event.params![0].id);
        handleNext();
        break;
      case StepFlowEventType.saveWorkFlowAndProcessReport:
        onValidateStepFlowEvent();
        handleNext();
        break;
      default:
        break;
    }
  };

  const onValidateStepFlowEvent = async () => {
    // Clone the current step flow and user progress
    const _currentStepFlow = cloneDeep(currentStepFlow);
    const _progress = cloneDeep(userProgress);
    if (!_currentStepFlow || !_progress) return;
  
    try {
      // Save profile photo
      const result = await saveProfilePhoto(validatedTintPhoto);
  
      // Update step result
      updateStepResult(_currentStepFlow, result.id);
  
      // Update progress steps
      updateProgressSteps(_progress, _currentStepFlow);
  
      // Save user progress
      const userProgressResult = await saveUserProgress(_progress);
  
      // Process color report
      await processColorReportAsync();
  
      // Fetch and update profile
      await fetchAndUpdateProfile();
  
      // Dispatch workflow session if available
      if (userProgressResult) {
        dispatch(setWorkflowSession(userProgressResult));
      }
    } catch (error) {
      // Log the error for debugging purposes
      console.error('An error occurred in onValidateStepFlowEvent:', error);
      showBoundary('An error occurred in onValidateStepFlowEvent.');
    }
  };

  const saveProfilePhoto = async (photo: string | null) => {
    try {
      return await saveUserPhotoQuery(photo).unwrap();
    } catch (error) {
      showBoundary('Error occurred while saving profile photo.');
      throw error;
    }
  };

  const updateStepResult = (_currentStepFlow: IStepFlowDefinition, resultId: string) => {
    if (!_currentStepFlow?.step?.result) return;
  
    _currentStepFlow.step.result.isCompleted = true;
    _currentStepFlow.step.result.categoryId = selectedColorId;
    _currentStepFlow.step.result.colorId = selectedNuanceId;
    _currentStepFlow.step.result.photoId = resultId;
  };

  const updateProgressSteps = (_progress: IWorkflow, _currentStepFlow: IStepFlowDefinition) => {
    const index = findIndex(_progress.steps, { id: _currentStepFlow.step?.id });
    if (index !== -1 && _progress.steps) {
      assign(_progress.steps[index], _currentStepFlow.step);
    }
  };

  const saveUserProgress = async (_progress: IWorkflow) => {
    try {
      return await saveUserProgressQuery(_progress).unwrap();
    } catch (error) {
      showBoundary('Error occurred while saving user progress.');
      throw error;
    }
  };

  const processColorReportAsync = async () => {
    try {
      await processColorReport(null).unwrap();
    } catch (error) {
      showBoundary('Error occurred while generating colors result.');
      throw error;
    }
  };

  const fetchAndUpdateProfile = async () => {
    try {
      const profile = await fetchProfileQuery(null).unwrap();
      dispatch(setUserProfile(profile));
    } catch (error) {
      showBoundary('Error occurred while getting user profile.');
      throw error;
    }
  };
  
  // Define Workflow steps
  const phtoProfileWorkFlow: IStepFlowComponent[] = [
    {
      order: 0,
      component: (
        <VideoContainer videoUrl="../assets/video/Intro.mp4" onComponentEvent={onComponentEvent} />
      ),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        hideStapper: false,
        showCorners: true,
      },
    },
    {
      order: 1,
      component: (
        <InstructionPageStepContainer
          frameNote={currentStepFlow?.step?.frameNote}
          instructionStepImages="skin"
          actionsComponent={
            <TintInstructionStepActions onComponentEvent={onComponentEvent} />
          }
        />
      ),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        displayLogo: false,
        workFlowStep: t("stepper.photo"),
        workFlow: t("stepper.tint"),
        workFlowProgress: 12,
        hideStapper: true,
        showCorners: true,
      },
    },
    {
      order: 2,
      component: (
        <ValidateTintPhotoStepComponent onComponentEvent={onComponentEvent} />
      ),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        displayLogo: false,
        workFlowStep: t("stepper.photo"),
        workFlow: t("stepper.tint"),
        workFlowProgress: 13,
        hideStapper: true,
        showCorners: true,
      },
    },
    {
      order: 3,
      component: (
        <TintTakePhotoStepComponent onComponentEvent={onComponentEvent} />
      ),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        displayLogo: false,
        workFlowStep: t("stepper.photo"),
        workFlow: t("stepper.tint"),
        workFlowProgress: 14,
        hideStapper: true,
        showCorners: true,
      },
    },
    {
      order: 4,
      component: (
        <TintColorSelectionPreStepAnimationStep onComponentEvent={onComponentEvent} />
      ),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        hideStapper: false,
        showCorners: false,
      },
    },
    {
      order: 5,
      component: (
        <TintColorSelectionStepComponent onComponentEvent={onComponentEvent} />
      ),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        displayLogo: false,
        workFlowStep: t("stepper.color"),
        workFlow: t("stepper.tint"),
        workFlowProgress: 15,
        hideStapper: true,
        showCorners: true,
      },
    },
    {
      order: 6,
      component: (
        <TintColorSelectionAfterStepAnimationStep onComponentEvent={onComponentEvent} />
      ),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        hideStapper: false,
        showCorners: false,
      },
    },
    {
      order: 7,
      component: (
        <TintNuanceColorSelectionStepComponent onComponentEvent={onComponentEvent} />
      ),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        displayLogo: false,
        workFlowStep: t("stepper.nuance"),
        workFlow: t("stepper.tint"),
        workFlowProgress: 16,
        hideStapper: true,
        showCorners: true,
      },
    },
    {
      order: 8,
      component: (
        <FinalRecapAnimationComponent
          hairDisplayPoints={{ left: 100, top: 50 }}
          tentDisplayPoints={{ left: 100, top: 500 }}
          eyeDisplayPoints={{ right: 50, top: 300 }}
          onComponentEvent={onComponentEvent} />
      ),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        hideStapper: false,
        showCorners: false,
      },
    },
    {
      order: 9,
      component: (
        <ResultGenerationStep onComponentEvent={onComponentEvent} />
      ),
      isProcessed: false,
      componentEvent: onComponentEvent,
      stepperConfiguration: {
        hideStapper: false,
        showCorners: true,
      },
    },
  ];

  const [activeStepIndex, , handleNext, reset, goToStep] = useStepsFlowComponentsManager({ stepsComponents: phtoProfileWorkFlow });

  return <div>{phtoProfileWorkFlow[activeStepIndex]?.component}</div>;
};

export default TintWorkFlow;
function saveUserPhotoQuery(validatedPhotoProfile: any) {
  throw new Error("Function not implemented.");
}

