import { useTranslation } from "react-i18next";
import { IStepFlowComponentBase, StepFlowEventType } from "../../types/StepFlowComponent.types";

export interface IEyeInstructionStepActionsProps extends IStepFlowComponentBase { }

const EyePatternInstructionAction = ({ onComponentEvent }: IEyeInstructionStepActionsProps) => {
  const { t } = useTranslation("common");
  const handleValidateInstruction = () => {
    onComponentEvent({
      type: StepFlowEventType.completed,
      params: [{ stepIndex: 1 }],
    });
  };

  return (
    <div className="instruction_page_buttons">
      <button className="take_pic" onClick={handleValidateInstruction}>
        {t("instructionActions.IUnderstand")}
      </button>
    </div>
  );
};

export default EyePatternInstructionAction;