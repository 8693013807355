import { useTranslation } from "react-i18next";

export interface INuanceColorSelectionStepActionsProps {
    colorSelected: boolean;
    validateHaireNuanceEvent: () => void;

}
const HaireNuanceSelectionStepActions = ({ colorSelected, validateHaireNuanceEvent }: INuanceColorSelectionStepActionsProps) => {
    const { t } = useTranslation("common");
    return (
        <>
            <button disabled={!colorSelected} onClick={validateHaireNuanceEvent} className="color_select_button">
                {t("hairNuanceSelection.validateNuance")}
            </button>

        </>
    );
};
export default HaireNuanceSelectionStepActions;