import { useState } from "react";
import ColorSelectorContainer from "../../components/ColorSelectorContainer";
import { IStepFlowComponentBase, StepFlowEventType } from "../../types/StepFlowComponent.types";
import { useAppDispatch } from "../../hooks";
import { resetCurrentSessionPhoto } from "../../features/photosManagment/sessionPhotosSlice";
import HaireNuanceSelectionStepActions from "./nuanceColorSelectionStepActions";
import { useSelector } from "react-redux";
import { selectValidatedHairePhoto } from "../../features/photosManagment/sessionPhotosSelectors";
import { resetHairSelectedColor, resetHairSelectedColorNuance, setHairSelectedColorNuance } from "../../features/colorsManagment/sessionColorsSlice";
import { selectHairColor, selectHairColorNuancesList } from "../../features/colorsManagment/sessionColorsSelectors";
import { IColorProfile } from "../../types/backend.types";
import { useTranslation } from "react-i18next";
import LayoutLoader from "../../components/LayoutLoader";

export interface INuanceColorSelectionStepProps extends IStepFlowComponentBase { }

const NuanceColorSelectionStepComponent = ({ onComponentEvent, isLoading }: INuanceColorSelectionStepProps) => {
    const { t } = useTranslation("common");
    const [isColorSelected, setIsColorSelected] = useState<boolean>(false);
    const [colorSelected, setColorSelected] = useState<IColorProfile | null>(null);
    const hairePicture = useSelector(selectValidatedHairePhoto);
    const HairSelectedColor = useSelector(selectHairColor);
    const hairColorNuancesList = useSelector(selectHairColorNuancesList);
    const dispatch = useAppDispatch();

    const handleBackEvent = () => {
        dispatch(resetCurrentSessionPhoto());
        dispatch(resetHairSelectedColorNuance());
        dispatch(resetHairSelectedColor());
        onComponentEvent({ type: StepFlowEventType.Reset });
    };

    const handleColorSelectedEvent = (e: IColorProfile) => {
        setIsColorSelected(true);
        setColorSelected(e);
        dispatch(setHairSelectedColorNuance(e));
    };

    const handleValidateHaireNuanceEvent = () => {
        onComponentEvent({ type: StepFlowEventType.SelectNuance, params: [colorSelected] });
    };

    return (
        <>
            {isLoading && <LayoutLoader />}

            <ColorSelectorContainer
                colors={hairColorNuancesList}
                selectedColorBackground={{ value: "", isHexCode: false }}
                title={t("hairNuanceSelection.title")}
                picture={hairePicture}
                subTitle={`${t("hairNuanceSelection.subTitle")} ${HairSelectedColor?.name}`}
                backEvent={handleBackEvent}
                selectColorEvent={e => handleColorSelectedEvent(e)}
                actionsComponent={
                    <HaireNuanceSelectionStepActions
                        colorSelected={isColorSelected}
                        validateHaireNuanceEvent={handleValidateHaireNuanceEvent}
                    />} />

        </>
    );
};
export default NuanceColorSelectionStepComponent;