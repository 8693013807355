import { useState } from "react";
import Cropper, { Point } from "react-easy-crop";
import frame from "../assets/image/camera/cameraFrame.svg";
import backArrow from "../assets/image/menu/backArrow.svg";
import { IStepFlowContainerBase } from "../types/StepFlowComponent.types";
import getCroppedImg from "./pictureValidationFunction";
import { useTranslation } from "react-i18next";
import SkeletonComponent from "./SkeletonComponent";

export interface IUserPictureValidationContainerProps
  extends IStepFlowContainerBase {
  picture: string;
  displayFaceFrame: boolean;
  cropperSize?: number;
  cropperEvent: (croppedPicture: string) => void;
  rounded: boolean;
}
const UserPictureValidationContainer = ({
  picture,
  displayFaceFrame,
  cropperSize,
  actionsComponent,
  cropperEvent,
  backEvent,
  rounded,
}: IUserPictureValidationContainerProps) => {
  const { t } = useTranslation("common");
  const [pictureIsLoaded, setPictureIsLoaded] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const _cropperSize = cropperSize ? cropperSize : 60;
  const onCropComplete = async (
    _croppedArea: Point,
    croppedAreaPixels: Point
  ) => {
    const croppedPicture = await getCroppedImg(picture, croppedAreaPixels);
    cropperEvent(croppedPicture);
  };

  const handleGoBackFunction = () => {
    backEvent && backEvent();
  };

  return (
    <div className="picture_validation_container">
      <button className="back_button" onClick={handleGoBackFunction}>
        <img src={backArrow} alt="" />
        <p>{t("validatePhoto.return")}</p>
      </button>


      <div className="cropper_container">
        {!pictureIsLoaded && <SkeletonComponent />}
        <Cropper
          onMediaLoaded={() => setPictureIsLoaded(true)}
          classes={{ mediaClassName: "selected_image_in_cropper", cropAreaClassName: "cropper_area" }}
          image={picture}
          crop={crop}
          zoom={zoom}
          showGrid={false}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          aspect={rounded ? 4 / 3 : 3 / 4}
          cropShape={`${rounded ? "round" : "rect"}`}
          cropSize={rounded ? { height: _cropperSize, width: _cropperSize } : { height: 340, width: 260 }}
        />
        {displayFaceFrame && (
          <img className="camera_frame" src={frame} alt="" />
        )}
      </div>

      <div className="buttons">{actionsComponent}</div>
    </div>
  );
};

export default UserPictureValidationContainer;
