import { useState } from "react";
import ColorSelectorContainer from "../../components/ColorSelectorContainer";
import { IStepFlowComponentBase, StepFlowEventType } from "../../types/StepFlowComponent.types";

import { useAppDispatch } from "../../hooks";
import { resetEyeSessionPhoto } from "../../features/photosManagment/sessionPhotosSlice";
import { useSelector } from "react-redux";
import { selectValidatedEyePhoto } from "../../features/photosManagment/sessionPhotosSelectors";
import { resetEyeSelectedColor, resetEyeSelectedColorNuance } from "../../features/colorsManagment/sessionColorsSlice";
import { selectEyeColor, selectEyeTemperatureList } from "../../features/colorsManagment/sessionColorsSelectors";
import { IColorProfile } from "../../types/backend.types";
import { useTranslation } from "react-i18next";
import LayoutLoader from "../../components/LayoutLoader";
import EyeTemperatureColorSelectionStepActions from "./EyeTemperatureColorSelectionStepActions";

export interface IEyeTemperatureColorSelectionStepProps extends IStepFlowComponentBase { }

const EyeTemperatureColorSelectionStepComponent = ({ onComponentEvent, isLoading }: IEyeTemperatureColorSelectionStepProps) => {
  const { t } = useTranslation("common");
  const dispatch = useAppDispatch();

  const EyePicture = useSelector(selectValidatedEyePhoto);
  const selectedEyeColor = useSelector(selectEyeColor);
  const eyeTemperatures = useSelector(selectEyeTemperatureList);

  const [isColorSelected, setIsColorSelected] = useState<boolean>(false);
  const [colorSelected, setColorSelected] = useState<IColorProfile | null>(null);

  const handleBackEvent = () => {
    dispatch(resetEyeSessionPhoto());
    dispatch(resetEyeSelectedColorNuance());
    dispatch(resetEyeSelectedColor());
    onComponentEvent({ type: StepFlowEventType.Reset });
  };

  const handleColorSelectedEvent = (e: IColorProfile) => {
    setIsColorSelected(true);
    setColorSelected(e);
    //dispatch(setEyeSelectedColor(e));
  };

  const handleValidateEyeTemperatureEvent = () => {
    onComponentEvent({ type: StepFlowEventType.SelectTemperature, params: [colorSelected] });
  };

  return (
    <>
      {isLoading && <LayoutLoader />}

      <ColorSelectorContainer
        colors={eyeTemperatures}
        selectedColorBackground={{ value: "", isHexCode: false }}
        title={isColorSelected ? `${colorSelected?.title}` : t("eyeTemperatureSelection.title")}
        picture={EyePicture}
        subTitle={`${t("eyeTemperatureSelection.subTitle")} ${selectedEyeColor?.title}`}
        backEvent={handleBackEvent}
        selectColorEvent={e => handleColorSelectedEvent(e)}
        actionsComponent={
          <EyeTemperatureColorSelectionStepActions colorSelected={isColorSelected} validateTemperatureEvent={handleValidateEyeTemperatureEvent} />
        }
      />
    </>
  );
};
export default EyeTemperatureColorSelectionStepComponent;