import { useTranslation } from "react-i18next";
import backArrow from "../assets/image/menu/backArrow.svg";
import imageCover from "../assets/image/selectColor/selectedCover.png";
import { IColorProfile } from "../types/backend.types";

interface IPatternSelectionComponent {
  handleBackEvent: () => void;
  handleSelectEvent: (color: IColorProfile) => void;
  handleValidateEvent: () => void;
  selectedItem: IColorProfile | null;
  picture: string | null;
  patternList: IColorProfile[];
}

const PatternSelectionComponent = ({ handleBackEvent, handleSelectEvent, handleValidateEvent, picture, patternList, selectedItem }: IPatternSelectionComponent) => {
  const { t } = useTranslation("common");
  const baseUrl = process.env.REACT_APP_BACKEND_URL;

  return (
    <div className="main_pattern_container">
      <button className="back_button" onClick={handleBackEvent}>
        <img src={backArrow} alt="" />
        <p>{t("eyePatternSelection.return")}</p>
      </button>

      <div className="display_pattern_container">
        <div className="pattern_top_circle" style={selectedItem ? { backgroundImage: `url(${baseUrl + "/" + selectedItem?.thumbnailUrl})` } : {}}>
          {!selectedItem && <p className="pattern_display_text">{t("eyePatternSelection.choosePatern")}</p>}
        </div>

        <div className="pattern_line"></div>

        <div className="pattern_buttom_circle" style={{ background: `url(${picture})` }}></div>
      </div>

      <div className="pattern_text">
        <h3 className="select_color_header">{t("eyePatternSelection.title")}</h3>
        <p className="select_color_sub_header">
          {t("eyePatternSelection.subtitle")}
        </p>
      </div>

      <div className="color_selector">
        {patternList.map((color: any) => (
          <div
            key={color.id}
            className={
              selectedItem?.id === color.id
                ? "color_container active_color"
                : "color_container"
            }
            onClick={() => handleSelectEvent(color)}
            style={color.color && !color.thumbnailUrl ? { backgroundColor: color.color } : {}}
          >
            <img src={color ? `${baseUrl}/${color.thumbnailUrl}` : imageCover} alt="" />

          </div>
        ))}
      </div>

      <button disabled={!selectedItem?.id} onClick={handleValidateEvent} className="color_select_button">
        {t("eyePatternSelection.validatePattern")}
      </button>
    </div>
  );
};

export default PatternSelectionComponent;