import { apiSlice } from "../api/apiSlice";
import { IUserProfile } from "../types/backend.types";
import { ILoginForm, IRegisterFrom, IWaitingListForm } from "./Register/types";
import {
  IMagnifisensesUser,
  IProcessPaymentBody,
  IResetPasswordRequest,
  IRestorePasswordFrom,
  IValidateRecaptchaTokenRequest,
  IValidateTokenRequest,
} from "./types";

export const authApiSlice = apiSlice.injectEndpoints({
  // check auth
  endpoints: (builder) => ({
    checkAuthQuery: builder.mutation<IMagnifisensesUser, any>({
      query: () => ({
        url: "/account/check-auth",
        method: "GET",
      }),
    }),
    // waiting list
    waitingListQuery: builder.mutation({
      query: (waitingBody: IWaitingListForm) => ({
        url: "/account/register-waiting-list",
        method: "POST",
        body: { ...waitingBody },
      }),
    }),
    // login
    loginQuery: builder.mutation({
      query: (credentials: ILoginForm) => ({
        url: "/account/login?useCookies=true",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    // logout
    logOutQuery: builder.mutation({
      query: () => ({
        url: "/account/logout",
        method: "POST",
      }),
    }),
    // register
    selfRegisterQuery: builder.mutation({
      query: (body: IRegisterFrom) => ({
        url: "/account/custom-register",
        method: "POST",
        body: { ...body },
      }),
    }),
    // fetch user profile
    fetchProfileQuery: builder.mutation<IUserProfile, any>({
      query: () => ({
        url: "/profile/fetch",
        method: "GET",
      }),
    }),
     // reset user profile
     resetProfileQuery: builder.mutation<IUserProfile, any>({
      query: () => ({
        url: "/profile/reset",
        method: "DELETE",
      }),
    }),
    // restore password
    restorPasswordQuery: builder.mutation({
      query: (body: IRestorePasswordFrom) => ({
        url: "/account/forgotPassword",
        method: "POST",
        body: { ...body },
      }),
    }),
    // reset password
    resetPasswordQuery: builder.mutation({
      query: (body: IResetPasswordRequest) => ({
        url: "/account/resetPassword",
        method: "POST",
        body: { ...body },
      }),
    }),
    // check token
    validateTokenQuery: builder.mutation({
      query: (body: IValidateTokenRequest) => ({
        url: "/account/validate-token",
        method: "POST",
        body: { ...body },
      }),
    }),
    // check recaptch token
    validateRecaptchaTokenQuery: builder.mutation({
      query: (body: IValidateRecaptchaTokenRequest) => ({
        url: "/account/validate-recaptcha-token",
        method: "POST",
        body: { ...body },
      }),
    }),
    // verify-payement
    verifyPayementQuery: builder.mutation({
      query: () => ({
        url: "/checkout/verify-payment",
        method: "GET",
      }),
    }),
    // process-payement
    processPayementQuery: builder.mutation({
      query: (body: IProcessPaymentBody) => ({
        url: `/checkout/process?subscriptionType=${body.subscriptionType}&promoCode=${body.promoCode}&callbackUrl=/analysis`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useCheckAuthQueryMutation,
  useLoginQueryMutation,
  useLogOutQueryMutation,
  useSelfRegisterQueryMutation,
  useFetchProfileQueryMutation,
  useRestorPasswordQueryMutation,
  useValidateTokenQueryMutation,
  useResetPasswordQueryMutation,
  useValidateRecaptchaTokenQueryMutation,
  useVerifyPayementQueryMutation,
  useProcessPayementQueryMutation,
  useResetProfileQueryMutation,
  useWaitingListQueryMutation,
} = authApiSlice;
