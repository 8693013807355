import { useOutlet } from "react-router-dom";
import logo from "../assets/image/logo/logo.svg";
import navPicture from "../assets/image/menu/navPic.svg";
import Menu from "./Menu";
import { useSelector } from "react-redux";
import { selectStepperConfiguration } from "../containers/UserWorkFlow/selectors";
import { useTranslation } from "react-i18next";


const NavBarComponent = () => {
  const { t } = useTranslation('common');
  const stepperConfiguration = useSelector(selectStepperConfiguration);
  const outlet = useOutlet();

  const desktopPhasesList: string[] = [t("stepper.face"), t("stepper.hair"), t("stepper.eye"), t("stepper.tint")];
  return (
    <>
      {stepperConfiguration?.hideStapper && (
        <nav className="navBar_component">
          <Menu />

          {!stepperConfiguration?.displayLogo && (
            <ul className="navBar_items_desktop">
              {desktopPhasesList.map((phase) => (
                <li
                  key={desktopPhasesList.indexOf(phase)}
                  className={
                    phase === stepperConfiguration?.workFlow
                      ? "navBar_item active"
                      : "navBar_item"
                  }
                >
                  {phase}
                </li>
              ))}
            </ul>
          )}

          {!stepperConfiguration?.displayLogo &&
            stepperConfiguration?.workFlow &&
            stepperConfiguration.workFlowStep && (
              <ul className="navBar_items_mobile">
                <li className="navBar_item">{stepperConfiguration.workFlow}</li>
                <li>.</li>
                <li className="navBar_item">
                  {stepperConfiguration.workFlowStep}
                </li>
              </ul>
            )}

          {stepperConfiguration?.displayLogo && (
            <img className="navbar_logo" src={logo} alt="magnificent logo" />
          )}

          <div className="progress_bar">
            <span
              className="progress_bar_color"
              style={{
                width: `${100 / (17 / (stepperConfiguration?.workFlowProgress ?? 1))
                  }%`,
              }}
            ></span>

          </div>
        </nav>
      )}
      {outlet}
    </>
  );
};

export default NavBarComponent;
