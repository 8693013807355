import { useState } from "react";
import ColorSelectorContainer from "../../components/ColorSelectorContainer";
import { IStepFlowComponentBase, StepFlowEventType } from "../../types/StepFlowComponent.types";

import { useAppDispatch } from "../../hooks";
import { resetEyeSessionPhoto } from "../../features/photosManagment/sessionPhotosSlice";
import EyeNuanceSelectionStepActions from "./EyeNuanceColorSelectionStepActions";
import { useSelector } from "react-redux";
import { selectValidatedEyePhoto } from "../../features/photosManagment/sessionPhotosSelectors";
import { resetEyeSelectedColor, resetEyeSelectedColorNuance, setEyeSelectedColorNuance } from "../../features/colorsManagment/sessionColorsSlice";
import { selectEyeColorNuancesList, selectEyeColorNuances, selectEyeColor } from "../../features/colorsManagment/sessionColorsSelectors";
import { IColorProfile } from "../../types/backend.types";
import { useTranslation } from "react-i18next";
import LayoutLoader from "../../components/LayoutLoader";

export interface INuanceColorSelectionStepProps extends IStepFlowComponentBase { }

const EyeNuanceColorSelectionStepComponent = ({ onComponentEvent, isLoading }: INuanceColorSelectionStepProps) => {
  const { t } = useTranslation("common");
  const dispatch = useAppDispatch();
  const EyePicture = useSelector(selectValidatedEyePhoto);
  const selectedEyeColor = useSelector(selectEyeColor);
  const EyeColorNuanceList = useSelector(selectEyeColorNuancesList);
  const [isColorSelected, setIsColorSelected] = useState<boolean>(false);
  const [colorSelected, setColorSelected] = useState<IColorProfile | null>(null);

  const handleBackEvent = () => {
    dispatch(resetEyeSessionPhoto());
    dispatch(resetEyeSelectedColorNuance());
    dispatch(resetEyeSelectedColor());
    onComponentEvent({ type: StepFlowEventType.Reset });
  };

  const handleColorSelectedEvent = (e: IColorProfile) => {
    setIsColorSelected(true);
    setColorSelected(e);
    dispatch(setEyeSelectedColorNuance(e));
  };

  const handleValidateEyeNuanceEvent = () => {
    onComponentEvent({ type: StepFlowEventType.SelectNuance, params: [colorSelected] });
  };

  return (
    <>
      {isLoading && <LayoutLoader />}

      <ColorSelectorContainer
        colors={EyeColorNuanceList}
        selectedColorBackground={{ value: "", isHexCode: false }}
        title={isColorSelected ? `${colorSelected?.title}` : t("eyeNuanceSelection.title")}
        picture={EyePicture}
        subTitle={`${t("eyeNuanceSelection.subTitle")} ${selectedEyeColor?.name ?? ''}`}
        backEvent={handleBackEvent}
        selectColorEvent={e => handleColorSelectedEvent(e)}
        actionsComponent={
          <EyeNuanceSelectionStepActions colorSelected={isColorSelected} validateEyeNuanceEvent={handleValidateEyeNuanceEvent} />
        }
      />
    </>
  );
};
export default EyeNuanceColorSelectionStepComponent;