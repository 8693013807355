import { createSlice } from "@reduxjs/toolkit";
import { IPhotoProfilWorkFlowState } from "./types";

const initialState: IPhotoProfilWorkFlowState = {
  profilePicture: null,
  validatedProfilePicture: null
};

const photoProfileWFSlice = createSlice({
    name: 'profilePhotoWf',
    initialState,
    reducers: {
    }
});

export const {} = photoProfileWFSlice.actions;
export default photoProfileWFSlice;
