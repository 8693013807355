
export const usePasswordValidation = () => {
 
    const validatePasswordConfirmation = (confirmation: string, password: string) => {
      return confirmation === password || 'Password confirmation do not match';
    };
  
    return {
      validatePasswordConfirmation
    };
  };