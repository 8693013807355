import { useTranslation } from "react-i18next";

export interface IValidateTintPhotoStepActionsProps {
  takeAnOtherPhotoEvent: () => void;
  validatePictureEvent: () => void;
}
const ValidateTintPhotoStepActions = ({ takeAnOtherPhotoEvent, validatePictureEvent }: IValidateTintPhotoStepActionsProps) => {
  const { t } = useTranslation("common");
  const handlePictureValidation = async () => {
    validatePictureEvent();
  };

  const handleTakeAnOtherPhoto = () => {
    takeAnOtherPhotoEvent();
  };
  return (
    <>
      <button className="recommencer_button" onClick={handleTakeAnOtherPhoto}>
        {t("validatePhoto.takeAnotherPicture")}
      </button>
      <button className="valide_button" onClick={handlePictureValidation}>
        {t("validatePhoto.validatePicture")}
      </button>
    </>
  );
};
export default ValidateTintPhotoStepActions;