import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserProfile } from "./selectors";
import { MagRoutes } from "../common/MagRoutes";

const CompleteProfileGuard = () => {
    const userProfile = useSelector(selectUserProfile);

    if (userProfile && userProfile.isCompleted) {
        return <>
            <Outlet />
        </>;
    }else {
        return <Navigate to={MagRoutes.analysisRouter} replace />;
    }
}

export default CompleteProfileGuard;