import { useForm, SubmitHandler } from "react-hook-form";
import { IRegisterFrom } from "./types";
import { setEmail } from "../authSlice";
import { useEmailValidation } from "../../hooks/EmailValidationHook";
import { usePasswordValidation } from "./PasswordValidationHook";
import { useSelfRegisterQueryMutation } from "../authApiSlice";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks";
import { MagRoutes } from "../../common/MagRoutes";
import { useEffect, useState } from "react";
import LayoutLoader from "../../components/LayoutLoader";
import eyeIcon from "../../assets/image/forms/passwordEye.svg";
import ReCAPTCHA from "react-google-recaptcha";
import { useHandleBackendErrors } from "../../hooks/HandleBackendErrorsHook";
import { useValidateRecaptchaHook } from "../../hooks/ValidateRecaptchaHook";
import { useTranslation } from "react-i18next";
import { setStepperConfiguration } from "../../containers/UserWorkFlow/userWorkFlowSlice";

const Register = () => {
  const { t } = useTranslation("common");

  const [viewPassword, setViewPassword] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IRegisterFrom>();

  const { validateEmail } = useEmailValidation();
  const { validatePasswordConfirmation } = usePasswordValidation();
  const password = watch("password", "");
  const [selfRegisterQuery, { isLoading }] = useSelfRegisterQueryMutation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [backendValidationErrors, setBackendValidationErrors, handleErrors] = useHandleBackendErrors();
  const [validateRecaptcha, captchaRef, processingRecaptchaValidation] = useValidateRecaptchaHook();
  const handleViewPassword = () => setViewPassword(!viewPassword);

  const processRegister = (data: IRegisterFrom) => {
    selfRegisterQuery(data)
      .unwrap()
      .then(() => {
        setBackendValidationErrors([]);
        dispatch(setEmail(data.email));
        navigate(MagRoutes.EmailVerification);
      })
      .catch((err: any) => handleErrors(err));
  };

  const onSubmit: SubmitHandler<IRegisterFrom> = async (data) => {
    const validationResult = await validateRecaptcha();
    if (validationResult) {
      processRegister(data);
    } else {
      setBackendValidationErrors(["Error while validating recaptcha token"]);
    }
  };

  useEffect(() => {
    dispatch(setStepperConfiguration({ showCorners: true }));
  }, []);

  useEffect(() => {
    if (backendValidationErrors) {
      window.scrollTo({ top: 0 });
    }
  });

  return (
    <section className="register">

      {isLoading && <LayoutLoader />}

      <h1 className="register_header">{t("register.title")}</h1>
      <ul className="error_msg_list">
        {backendValidationErrors.map((err: string, index: number) => {
          return (
            <li className="error_msg_backend" key={index}>
              {err}
            </li>
          );
        })}
      </ul>
      <form
        className="register_form"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* First name*/}
        <div className="input_container">
          <input
            id="prenom"
            className="input_container_field"
            autoComplete="off"
            placeholder=""
            {...register("firstName", { required: true, maxLength: 20 })}
          />
          <label htmlFor="prenom" className="input_container_label">
            {t("register.firstNameLabel")}
          </label>
        </div>

        {errors.firstName?.type === "required" && (
          <span className="error_msg" role="alert">
            {t("register.firstNameRequiredErrorMsg")}
          </span>
        )}

        {/* Last name*/}
        <div className="input_container">
          <input
            id="nom"
            className="input_container_field"
            placeholder=""
            autoComplete="off"
            {...register("lastName", { required: true, maxLength: 20 })}
          />
          <label htmlFor="nom" className="input_container_label">
            {t("register.lastNameLabel")}
          </label>
        </div>

        {errors.lastName?.type === "required" && (
          <span className="error_msg" role="alert">
            {t("register.lastNameRequiredErrorMsg")}
          </span>
        )}

        {/* Email name*/}
        <div className="input_container">
          <input
            id="email"
            className="input_container_field"
            placeholder=""
            autoComplete="off"
            {...register("email", { required: true, validate: validateEmail })}
            aria-invalid={errors.email ? "true" : "false"}
          />
          <label htmlFor="email" className="input_container_label">
            {t("register.emailLabel")}
          </label>
        </div>

        {errors.email?.type === "required" && (
          <span className="error_msg" role="alert">
            {t("register.emailRequireErrorMsg")}
          </span>
        )}
        {errors.email && (
          <span className="error_msg" role="alert">
            {errors.email.message}
          </span>
        )}

        {/* Birth Date*/}
        <div className="datepicker_container">
          <input
            id="date"
            type="date"
            className="date_picker"
            autoComplete="off"
            required
            max="3000-12-31"
            {...register("dateOfBirth", { required: "birth day is required" })}
          />

          <label htmlFor="date" className="datepicker_label">
            {t("register.birthDateLabel")}
          </label>
        </div>

        {errors.dateOfBirth?.type === "required" && (
          <span className="error_msg">{t("register.birthDateReaquiredErrorMsg")}</span>
        )}

        {/* Password name*/}
        <div className="input_container">
          <input
            id="Password"
            className="input_container_field"
            placeholder=""
            autoComplete="off"
            {...register("password", {
              required: true,
            })}
            type={viewPassword ? "text" : "password"}
          />
          <label htmlFor="Password" className="input_container_label">
            {t("register.passwordLabel")}
          </label>
          <img
            src={eyeIcon}
            className="eye_icon"
            alt="eye icon"
            onClick={() => handleViewPassword()}
          />
        </div>

        {errors.password?.type === "required" && (
          <span className="error_msg" role="alert">
            {t("register.passwordRequiredErrorMsg")}
          </span>
        )}

        {errors.password && (
          <span className="error_msg">{errors.password.message}</span>
        )}

        {/* Confirm password*/}
        <div className="input_container">
          <input
            id="ConfirmPassword"
            className="input_container_field"
            placeholder=""
            autoComplete="off"
            {...register("confirmPassword", {
              required: true,
              validate: (value) =>
                validatePasswordConfirmation(value, password),
            })}
            type={viewPassword ? "text" : "password"}
          />
          <label htmlFor="ConfirmPassword" className="input_container_label">
            {t("register.ConfirmPasswordLabel")}
          </label>
          <img
            src={eyeIcon}
            className="eye_icon"
            alt="eye icon"
            onClick={() => handleViewPassword()}
          />
        </div>

        {errors.confirmPassword?.type === "required" && (
          <span className="error_msg" role="alert">
            {t("register.ConfirmPasswordRequireErrorMsg")}
          </span>
        )}
        {errors.confirmPassword && (
          <span className="error_msg">{errors.confirmPassword.message}</span>
        )}


        {/* Phone Number*/}
        <div className="input_container">
          <input
            id="phoneNum"
            className="input_container_field"
            placeholder=""
            autoComplete="off"
            {...register("phoneNumber")}
          />
          <label htmlFor="phoneNum" className="input_container_label">
            {t("register.phoneNumberLabel")}
          </label>
        </div>

        <p className="conditions">
          {t("register.acceptConditions.line01")}{" "}
          <Link className="register_links" to="">
            {t("register.acceptConditions.line02")}
          </Link>{" "}
          {t("register.acceptConditions.line03")}{" "}
          <Link className="register_links" to="">
            {t("register.acceptConditions.line04")}
          </Link>
          .
        </p>
        <ReCAPTCHA
          ref={captchaRef}
          sitekey={process.env.REACT_APP_RECAPTCHA_KEY ?? ""}
          size="invisible"
        />
        {/* submit button */}
        <button
          className="register_submit_button"
          type="submit"
          disabled={isLoading || processingRecaptchaValidation}
        >
          {t("register.createAccountLabel")}
        </button>
      </form>

      <footer className="register_footer">
        <p className="register_footer_text">
          {t("register.recaptchaConditions.line01")}{" "}
          <Link className="register_links" to="">
            {" "}
            {t("register.recaptchaConditions.line02")}
          </Link>{" "}
          {t("register.recaptchaConditions.line03")}{" "}
          <Link className="register_links" to="">
            {t("register.recaptchaConditions.line04")}
          </Link>{" "}
          {t("register.recaptchaConditions.line05")}
        </p>
      </footer>
    </section>
  );
};
export default Register;
