import { useRef } from "react";
import { IStepFlowComponentBase, StepFlowEventType } from "../../types/StepFlowComponent.types";
import { useAppDispatch } from "../../hooks";
import { setCurrentSessionPhoto } from "../../features/photosManagment/sessionPhotosSlice";
import { useTranslation } from "react-i18next";

export interface IVisageInstructionStepActionsProps extends IStepFlowComponentBase {

}
const VisageInstructionStepActions = ({ onComponentEvent }: IVisageInstructionStepActionsProps) => {
    const { t } = useTranslation("common");
    const fileUploader = useRef<HTMLInputElement>(null);
    const dispatch = useAppDispatch();

    const handleTakePicture = () => {
        onComponentEvent({ type: StepFlowEventType.TakePicture });
    };
    const handleLoadPicture = () => {
        fileUploader.current?.click();
    };
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];

        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const imageData = e.target?.result as string;
                dispatch(setCurrentSessionPhoto(imageData));
                onComponentEvent({ type: StepFlowEventType.LoadPicture });
            };

            reader.readAsDataURL(selectedFile);
        }
    };
    return (
        <>
            <div className="instruction_page_buttons">
                <button className="take_pic" onClick={handleTakePicture}>
                    {t("instructionActions.takePicture")}
                </button>
                <button className="upload_pic" onClick={handleLoadPicture}>
                    {t("instructionActions.uploadPicture")}
                </button>
            </div>
            <input className="input-upload"
                type="file"
                id="file"
                ref={fileUploader}
                onChange={handleFileChange}
                accept="image/*"
            />
        </>
    );
};
export default VisageInstructionStepActions;