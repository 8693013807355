import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { apiSlice } from './api/apiSlice';
import authSlice from '../app/auth/authSlice';
import { setupListeners } from '@reduxjs/toolkit/query';
import photoProfileWFSlice from './containers/PhotoProfileWorkFlow/photoProfileSlice';
import userWorkFlowSlice from './containers/UserWorkFlow/userWorkFlowSlice';
import haireStepFlowSlice from './containers/HairWorkFlow/haireStepFlowSlice';
import sessionPhotosSlice from './features/photosManagment/sessionPhotosSlice';
import sessionColorsSlice from './features/colorsManagment/sessionColorsSlice';

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authSlice.reducer,
    userWorkFlow: userWorkFlowSlice.reducer,
    sessionPhoto: sessionPhotosSlice.reducer,
    profilePhotoWf: photoProfileWFSlice.reducer,
    haireStepFlow: haireStepFlowSlice.reducer,
    sessionColors: sessionColorsSlice.reducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
