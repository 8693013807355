import { useTranslation } from "react-i18next";
import { useProcessPayementQueryMutation } from "../auth/authApiSlice";
import { IProcessPaymentBody } from "../auth/types";
import { useErrorBoundary } from "react-error-boundary";
import { useState } from "react";
import paymentSelected from "../assets/image/payment/paymentSelected.svg";
import paymentNotSelected from "../assets/image/payment/paymentNotSelected.svg";
import detailsArrowUp from "../assets/image/payment/paymentUpArrow.svg";
import detailsArrowDown from "../assets/image/payment/paymentDownArrow.svg";
import LayoutLoader from "../components/LayoutLoader";

const CheckoutComponent = () => {
    const [processPayementQuery, { isLoading }] = useProcessPayementQueryMutation();
    const { showBoundary } = useErrorBoundary();

    const { t } = useTranslation('common');

    const [selectedPremniumOffer, setSelectedPremniumOffer] = useState(true);

    const handleSelectOffer = (offer: boolean) => setSelectedPremniumOffer(offer);

    const handleCheckout = () => {
        const subscriptionType = selectedPremniumOffer === true ? "premium" : "basic";

        const subscriptionBody: IProcessPaymentBody = { subscriptionType: subscriptionType, promoCode: null };
        processPayementQuery(subscriptionBody)
            .then(response => {
                if ("data" in response)
                    window.location.href = response.data.url;
            }).catch(err => showBoundary(err));
    };

    return (
        <main className="payment_rapper">
            {isLoading && <LayoutLoader />}

            <div className="payment_main_section">
                <h2 className="payment_header">{t("payment.title")}</h2>
                <p className="payment_subheader">{t("payment.subtitle")}</p>

                <section
                    className={`offer_container ${selectedPremniumOffer ? "offer_container_selected" : ""}`}
                    onClick={() => handleSelectOffer(true)}
                >
                    <img src={selectedPremniumOffer ? paymentNotSelected : paymentSelected} alt="" className="offer_image" />
                    <div className="offer_section">
                        <h3 className={`offer_title ${selectedPremniumOffer ? "selected_text" : ""}`}>{t("payment.offersTitles.premium")}</h3>

                        <div className="collapsible_list">
                            <div className="details_div">
                                <p className="list_header">{t("payment.details.title")}</p>
                                <img src={selectedPremniumOffer ? detailsArrowUp : detailsArrowDown} alt="" className="details_arrow" />
                            </div>
                            <div className="content">
                                <ul className={`${!selectedPremniumOffer ? "hide" : ""}`}>
                                    <li>{t("payment.details.first")}</li>
                                    <li>4 {t("payment.details.second")}</li>
                                    <li>12 {t("payment.details.third")}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <p className={`offer_price ${selectedPremniumOffer ? "selected_text" : ""}`}>11.99 €</p>
                </section>

                <section
                    className={`offer_container ${!selectedPremniumOffer ? "offer_container_selected" : ""}`}
                    onClick={() => handleSelectOffer(false)}
                >
                    <img src={!selectedPremniumOffer ? paymentNotSelected : paymentSelected} alt="" className="offer_image" />
                    <div className="offer_section">
                        <h3 className={`offer_title ${!selectedPremniumOffer ? "selected_text" : ""}`}>{t("payment.offersTitles.pasique")}</h3>

                        <div className="collapsible_list">
                            <div className="details_div">
                                <p className="list_header">{t("payment.details.title")}</p>
                                <img src={!selectedPremniumOffer ? detailsArrowUp : detailsArrowDown} alt="" className="details_arrow" />
                            </div>
                            <div className="content">
                                <ul className={`${selectedPremniumOffer ? "hide" : ""}`}>
                                    <li>{t("payment.details.first")}</li>
                                    <li>2 {t("payment.details.second")}</li>
                                    <li>6 {t("payment.details.third")}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <p className={`offer_title ${!selectedPremniumOffer ? "selected_text" : ""}`}>6.99 €</p>
                </section>
            </div>

            <button className="payment_button" disabled={isLoading} onClick={handleCheckout}>{t("payment.button")}</button>
        </main>

    );
};

export default CheckoutComponent;


