"use client";
import { RouterProvider } from "react-router-dom";
import { router } from "./app/components/AppRouter";
function App() {


  return (
    <>
     <RouterProvider router={router} />
     </>
  );
}

export default App;
