import { useTranslation } from "react-i18next";

export interface ITintColorSelectionStepActionsProps {
  colorSelected: boolean;
  validateTintColorEvent: () => void;

}
const TintColorSelectionStepActions = ({ colorSelected, validateTintColorEvent }: ITintColorSelectionStepActionsProps) => {
  const { t } = useTranslation("common");
  return (
    <>
      <button disabled={!colorSelected} onClick={validateTintColorEvent} className="color_select_button">
        {t("tintColorSelection.validateColor")}
      </button>
    </>
  );
};

export default TintColorSelectionStepActions;