import cornerTopLeft from '../assets/image/corners/cornerTL.svg';
import cornerTopRight from '../assets/image/corners/cornerTR.svg';
import cornerBottomLeft from '../assets/image/corners/cornerBL.svg';
import cornerBottomRight from '../assets/image/corners/cornerBR.svg';

function Corners() {
  return (
    <>
      <img src={cornerTopLeft} alt='corner' className='corner tl' />
      <img src={cornerTopRight} alt='corner' className='corner tr' />
      <img src={cornerBottomLeft} alt='corner' className='corner bl' />
      <img src={cornerBottomRight} alt='corner' className='corner br' />
    </>
  );
}

export default Corners;
