import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IAuthState, IMagnifisensesUser } from "./types";
import { IUserProfile } from "../types/backend.types";

const initialState: IAuthState = {
    userId: null,
    emailAddress: null,
    isAuthenticated: null,
    userProfile: null
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setEmail: (state, action) => {
            state.emailAddress = action.payload;
        },
        setUser: (state, { payload }: PayloadAction<IMagnifisensesUser>) => {
            const { userId, emailAddress, isAuthenticated } = payload;
            state.userId = userId;
            state.emailAddress = emailAddress;
            state.isAuthenticated = isAuthenticated;
        },
        setUserProfile: (state, { payload }: PayloadAction<IUserProfile>) => {
            state.userProfile = payload;
        },
        setLogOut: state => {
            state.userId = null;
            state.emailAddress = null;
            state.isAuthenticated = false;
        }
    }
});

export const {setEmail, setUser, setUserProfile, setLogOut} = authSlice.actions;
export default authSlice;


