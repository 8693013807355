import { useSelector } from "react-redux";
import { IStepFlowComponentBase, StepFlowEventType } from "../../types/StepFlowComponent.types";
import { useEffect } from "react";
import { selectProfilePhoto } from "../../features/photosManagment/sessionPhotosSelectors";
import AfterStepAnimationContainer from "../../components/AfterStepAnimationContainer";
import { selectHairColor } from "../../features/colorsManagment/sessionColorsSelectors";
import { selectCurrentSession } from "../UserWorkFlow/selectors";
import useImageDimensions from "../../hooks/getImageDimensionsHook";


export interface IHaireColorSelectionAfterStepAnimationProps extends IStepFlowComponentBase { }

const HaireColorSelectionAfterStepAnimationStep = ({ onComponentEvent }: IHaireColorSelectionAfterStepAnimationProps) => {
    const profilePhoto = useSelector(selectProfilePhoto);
    const selectedHairColor = useSelector(selectHairColor);
    const userWorkFlow = useSelector(selectCurrentSession);
    const pictureDimensions = useImageDimensions(profilePhoto!);
    const hairAnimationCoordinates = userWorkFlow?.faceInfo?.geometry?.hair;
    const animationOffset = {
        top: 26
    };

    useEffect(() => {
        const haireAnimation = setTimeout(() => {
            onComponentEvent({ type: StepFlowEventType.completed });

        }, 5000);

        return () => {
            clearTimeout(haireAnimation);
        };
    }, [profilePhoto]);

    return (
        <AfterStepAnimationContainer
            picture={profilePhoto!}
            colorHexCode={selectedHairColor?.color!}
            colorName={selectedHairColor?.name!}
            nextSubStepName="Nuance"
            top={((hairAnimationCoordinates?.top! * 100) / pictureDimensions.height!) - animationOffset.top}
            left={(hairAnimationCoordinates?.left! * 100) / pictureDimensions.width!}
        />
    );
};

export default HaireColorSelectionAfterStepAnimationStep;
