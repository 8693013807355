import { useOutlet } from "react-router-dom";

export const CornerLayout = () => {
  const outlet = useOutlet();

  return (
    <div style={{ position: "relative", minHeight: "100dvh" }}>
      {outlet}
    </div>
  );
};

export default CornerLayout;
