import { useState } from "react";
import ColorSelectorContainer from "../../components/ColorSelectorContainer";
import { IStepFlowComponentBase, StepFlowEventType } from "../../types/StepFlowComponent.types";

import EyeColorSelectionStepActions from "./EyeColorSelectionStepActions";
import { useAppDispatch } from "../../hooks";
import { resetEyeSessionPhoto } from "../../features/photosManagment/sessionPhotosSlice";
import { useSelector } from "react-redux";
import { selectValidatedEyePhoto } from "../../features/photosManagment/sessionPhotosSelectors";
import { resetEyeSelectedColor, setEyeSelectedColor } from "../../features/colorsManagment/sessionColorsSlice";
import { selectEyeColorsList } from "../../features/colorsManagment/sessionColorsSelectors";
import { IColorProfile } from "../../types/backend.types";
import { useTranslation } from "react-i18next";

export interface IEyeColorSelectionStepProps extends IStepFlowComponentBase { };

const EyeColorSelectionStepComponent = ({ onComponentEvent }: IEyeColorSelectionStepProps) => {
  const { t } = useTranslation("common");
  const dispatch = useAppDispatch();
  const eyePicture = useSelector(selectValidatedEyePhoto);
  const eyeColorsList = useSelector(selectEyeColorsList);

  const [isColorSelected, setIsColorSelected] = useState<boolean>(false);
  const [colorSelected, setColorSelected] = useState<IColorProfile | null>(null);

  const handleBackEvent = () => {
    dispatch(resetEyeSessionPhoto());
    dispatch(resetEyeSelectedColor());
    onComponentEvent({ type: StepFlowEventType.Reset });
  };

  const handleColorSelectedEvent = (e: IColorProfile) => {
    setIsColorSelected(true);
    setColorSelected(e);
    dispatch(setEyeSelectedColor(e));
  };

  const handleValidateEyeColorEvent = () => {
    onComponentEvent({ type: StepFlowEventType.SelectColor, params: [colorSelected] });
  };

  return (
    <>
      <ColorSelectorContainer
        colors={eyeColorsList}
        selectedColorBackground={{ value: "", isHexCode: false }}
        title={isColorSelected ? colorSelected?.title : t("eyeColorSelection.title")}
        picture={eyePicture}
        subTitle={t("eyeColorSelection.subTitle")}
        backEvent={handleBackEvent}
        selectColorEvent={e => handleColorSelectedEvent(e)}
        actionsComponent={
          <EyeColorSelectionStepActions colorSelected={isColorSelected} validateEyeColorEvent={handleValidateEyeColorEvent} />
        }
      />
    </>
  );
};

export default EyeColorSelectionStepComponent;