import { useRef } from "react";
import { useAppDispatch } from "../../hooks";
import CameraContainer from "../../components/CameraContainer";
import { IStepFlowComponentBase, StepFlowEventType } from "../../types/StepFlowComponent.types";
import Webcam from "react-webcam";
import { resetCurrentSessionPhoto, setCurrentSessionPhoto } from "../../features/photosManagment/sessionPhotosSlice";


export interface IPhotoProfileStepComponentProps extends IStepFlowComponentBase {
}
const PhotoProfileStepComponent = ({ onComponentEvent }: IPhotoProfileStepComponentProps) => {
    const webcamRef = useRef<null | Webcam>(null);
    const dispatch = useAppDispatch();

    const handleCaptureEvent = (screenShot: string | null | undefined) => {
        dispatch(setCurrentSessionPhoto(screenShot));
        onComponentEvent({ type: StepFlowEventType.TakePicture });
    };

    const handleBackEvent = () => {
        dispatch(resetCurrentSessionPhoto());
        onComponentEvent({ type: StepFlowEventType.Reset });
    };

    return (
        <>
            <CameraContainer webCamRef={webcamRef} displayFaceFrame={true} backEvent={handleBackEvent} captureEvent={(e) => handleCaptureEvent(e)} />
        </>

    );
};

export default PhotoProfileStepComponent;