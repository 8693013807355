import { Link } from "react-router-dom";
import Corners from "./Corners";

const RouteNotFound = () => {

    return (
        <div className="not_found_page">
            <Corners />
            <p>
                Route not found
            </p>
            <Link to={"/"} className="not_found_link">Return to Home</Link>
        </div>
    );
};

export default RouteNotFound;