import { useSelector } from "react-redux";
import { IStepFlowComponentBase, StepFlowEventType } from "../../types/StepFlowComponent.types";
import { useAppDispatch } from "../../hooks";
import UserPictureValidationContainer from "../../components/PictureValidationContainer";
import { selectTintValidationPhoto } from "../../features/photosManagment/sessionPhotosSelectors";
import { resetTintSessionPhoto, setValidatedTintPhoto } from "../../features/photosManagment/sessionPhotosSlice";
import ValidateTintPhotoStepActions from "./ValidateTintPhotoStepActions";

export interface IalidateTintPhotoStepProps extends IStepFlowComponentBase { }

const ValidateTintPhotoStepComponent = ({ onComponentEvent }: IalidateTintPhotoStepProps) => {
  const dispatch = useAppDispatch();
  const profilePhoto = useSelector(selectTintValidationPhoto);

  const handlePictureValidation = () => {
    onComponentEvent({
      type: StepFlowEventType.GoToStep,
      params: [{ stepIndex: 4 }],
    });
  };

  const handleCropperEvent = (e: string) => {
    dispatch(setValidatedTintPhoto(e));
  };

  const handleTakeAnOtherPhotoEvent = () => {
    dispatch(resetTintSessionPhoto());
    onComponentEvent({
      type: StepFlowEventType.GoToStep,
      params: [{ stepIndex: 3 }],
    });
  };

  const handleGoBackFunction = () => {
    dispatch(resetTintSessionPhoto());
    onComponentEvent({ type: StepFlowEventType.Reset });
  };

  return (
    <UserPictureValidationContainer
      rounded={true}
      picture={profilePhoto}
      displayFaceFrame={false}
      cropperSize={250}
      backEvent={handleGoBackFunction}
      cropperEvent={(e) => handleCropperEvent(e)}
      actionsComponent={
        <ValidateTintPhotoStepActions
          takeAnOtherPhotoEvent={handleTakeAnOtherPhotoEvent}
          validatePictureEvent={handlePictureValidation}
        />
      }
    />
  );
};

export default ValidateTintPhotoStepComponent;
