import { useTranslation } from "react-i18next";

export interface IHaireColorSelectionStepActionsProps {
    colorSelected: boolean;
    validateHaireColorEvent: () => void;

}
const HaireColorSelectionStepActions = ({ colorSelected, validateHaireColorEvent }: IHaireColorSelectionStepActionsProps) => {
    const { t } = useTranslation("common");
    return (
        <>
            <button disabled={!colorSelected} onClick={validateHaireColorEvent} className="color_select_button">
                {t("hairColorSelection.validateColor")}
            </button>

        </>
    );
};
export default HaireColorSelectionStepActions;