import { useTranslation } from "react-i18next";
import Corners from "./Corners";
import { useEffect, useState } from "react";


export interface IAfterStepAnimationContainerProps {
  picture: string;
  nextSubStepName: string;
  colorName: string | null;
  colorNuance?: string | null;
  colorHexCode: string | null;
  top: number;
  left: number;
}

const AfterStepAnimationContainer = ({ picture, top, left, nextSubStepName, colorName, colorHexCode, colorNuance }: IAfterStepAnimationContainerProps) => {
  const { t } = useTranslation("common");
  const [faded, setFaded] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState(false);

  useEffect(() => {
    const faded = setTimeout(() => {
      setFaded(true);
    }, 1000);

    const background = setTimeout(() => {
      setBackgroundColor(true);
    }, 1200);

    return () => {
      clearTimeout(faded);
      clearTimeout(background);
    };
  });

  return (
    <main className="recap_animation_container">
      <section className="recap_profile_picture_container">
        <img className="recap_animation_picture" src={picture} alt="" />
        <div
          className={`animation_result_div ${faded ? "faded_animation" : ""}`}
          style={{
            top: `${top}%`,
            left: `${left}%`
          }}
        >
          <div
            className="result_color_top"
            style={{
              transition: backgroundColor ? "all 1s" : "",
              backgroundColor: backgroundColor ? `${colorHexCode}` : "",
            }}
          ></div>
          <div className="result_color_bottom">
            <p className="result_text">{colorName}</p>
            <p className="result_subtext">{colorNuance}</p>
          </div>
        </div>

        <div className="recap_text_container">
          <h3 className="recap_text_h3">{t("stepAnimation.passTo")}</h3>
          <h2 className="recap_text_h2">{nextSubStepName}</h2>
        </div>
      </section>
    </main>
  );
};

export default AfterStepAnimationContainer;
